import { Breadcrumb, Button, Col, PageHeader, Pagination, Row } from 'antd';
import React, { Fragment, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PlaceParticipantContext } from '../contexts/placeParticipantContext';
import { useStockItemListQuery } from '../Generated/graphql';
import { formatCurrency } from '../utils/currency';
import { dateTimeString } from '../utils/date';

const StockItemListScreen: React.FC = () => {
  const history = useHistory();

  const { placeParticipantId } = useContext(PlaceParticipantContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  if (!placeParticipantId) return <div>Error</div>;

  const data = useStockItemListQuery({
    variables: { orderBy: '', skip: (currentPage - 1) * pageSize, take: pageSize },
  });

  function itemRender(
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>,
  ) {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  }

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Stock</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/store/stock/items')}
        title="Stock"
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        {data.loading ? (
          <div>...loading</div>
        ) : (
          <>
            <div style={{ marginBottom: 20 }}>
              {data.data?.stockItemList.items.map((x) => (
                <Row key={x.id} gutter={8} style={{ padding: 10, borderBottom: '1px solid lightgray' }}>
                  <Col span="4">
                    <Link to={'./items/' + x.id}>{dateTimeString(x.createdAt)}</Link>
                  </Col>

                  <Col span="14">
                    <Link to={'/store/products/' + x.product.id}>{x.product.name}</Link>
                    <div>{x.type}</div>
                  </Col>
                  <Col span="3" style={{ textAlign: 'right', whiteSpace: 'normal' }}>
                    <div>{dateTimeString(x.inDateTime, '-')}</div>
                    <div>
                      {x.inOrder && <Link to={'/purchaser/orders/' + x.inOrder.id}>{x.inOrder.orderId}</Link>}
                      {x.inStockChange && (
                        <Link to={'/store/stock/changes/' + x.inStockChange.id}>Change ({x.inStockChange.type})</Link>
                      )}
                    </div>
                    <div>{formatCurrency(x.inPriceNet, '-')}</div>
                  </Col>
                  <Col span="3" style={{ textAlign: 'right' }}>
                    <div>{dateTimeString(x.outDateTime, '-')}</div>
                    {x.outOrder && <Link to={'/store/orders/' + x.outOrder.id}>{x.outOrder.orderId}</Link>}
                    {x.outStockChange && (
                      <Link to={'/store/stock/changes/' + x.outStockChange.id}>Change ({x.outStockChange.type})</Link>
                    )}
                    <div>{formatCurrency(x.outPriceNet, '-')}</div>
                  </Col>
                </Row>
              ))}
            </div>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={data.data?.stockItemList.totalCount}
              itemRender={itemRender}
              onChange={(x) => {
                setCurrentPage(x);
              }}
              onShowSizeChange={(current, size) => setPageSize(size)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default StockItemListScreen;
