import { formatDistance, parseISO, format as f, parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';

export function timeAgoString(date: string): string {
  return formatDistance(parseISO(date), new Date(), { addSuffix: true, locale: enUS });
}

export function timeAgo(date: Date): string {
  return formatDistance(date, new Date(), { addSuffix: true, locale: enUS });
}

export function dateTimeString(date: string, def = ''): string {
  if (!date) return def;
  return f(parseISO(date), 'dd-MMM-yyyy HH:mm');
}

export function dateToString(date: string): string {
  return f(parseISO(date), 'dd-MM-yyyy');
}

export function stringToDateUTC(date: string): Date {
  const parsed = parse(date, 'dd-MM-yyyy', new Date());

  return zonedTimeToUtc(parsed, 'UTC');
}
