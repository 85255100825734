import { User, UserManager } from 'oidc-client';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfigContext } from './configContext';

// tslint:disable-next-line:max-line-length
const host = `${window.location.protocol}//${window.location.hostname}${
  window.location.port && window.location.port !== '' ? ':' + window.location.port : ''
}`;

interface Context {
  loading: boolean;
  user: User | null;
  signin: () => void;
  signinCallback: () => void;
  signout: () => void;
  signoutCallback: () => void;
}

const AuthContext = React.createContext<Context>({} as Context);

const AuthProvider: React.FC = (props) => {
  const { authorityUri } = useContext(ConfigContext);

  // tslint:disable-next-line:typedef
  const settings = {
    authority: authorityUri,
    client_id: 'biasaio-admin',
    redirect_uri: `${host}/callback`,
    silent_redirect_uri: `${host}/renew`,
    // tslint:disable-next-line:object-literal-sort-keys
    post_logout_redirect_uri: `${host}/signoutcallback`,
    response_type: 'id_token token',
    scope:
      'openid profile orderindo-account-api orderindo-place-api orderindo-product-api orderindo-cart-api orderindo-order-api orderindo-file-api orderindo-discount-api orderindo-reporting orderindo-graphql',
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    loadUserInfo: true,
  };

  const [userManager] = useState(new UserManager(settings));

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const history = useHistory();

  useEffect(() => {
    const userJson = localStorage.getItem('user');
    if (userJson) {
      const user = JSON.parse(userJson) as User;
      console.log('user from storage', user, new Date(user.expires_at), user.expires_at > new Date().getTime());
      if (user.expires_at > new Date().getTime()) {
        setUser(user);
        setLoading(false);
        return;
      } else {
        console.log('xxx');
      }
    }

    userManager
      .getUser()
      .then((x) => {
        if (x) {
          x.expires_at = new Date(new Date().getTime() + x.expires_at).getTime();
          console.log('user', x);

          localStorage.setItem('user', JSON.stringify(x));
          setUser(x);
          setLoading(false);
        } else {
          setUser(null);
          localStorage.removeItem('user');
        }
      })
      .catch((e) => {
        console.log('error', e);
      });
  }, [userManager]);

  const signin = useCallback(() => {
    userManager.signinRedirect();
  }, [userManager]);

  const signinCallback = useCallback(() => {
    console.log('SigninCallback', history);
    userManager
      .signinRedirectCallback()
      .then((user) => {
        window.location.href = user.state && user.state.returnUrl ? user.state.returnUrl : '/';
        // history.replace(user.state && user.state.returnUrl ? user.state.returnUrl : '/')
        //history.push(user.state && user.state.returnUrl ? user.state.returnUrl : '/');
        setUser(user);
      })
      .catch((e) => {
        console.log('SigninCallback, error', e);
      });
  }, [userManager]);

  const signout = useCallback(() => {
    localStorage.removeItem('user');
    userManager.signoutRedirect();
  }, [userManager]);

  const signoutCallback = useCallback(() => {
    console.log('SignoutCallback', history);
    userManager
      .signoutRedirectCallback()
      .then((v) => {
        console.log('SignoutCallback, then', v);
        history.push('/');
        setUser(null);
      })
      .catch((e) => {
        console.log('SignoutCallback, error', e);
      });
  }, [userManager]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        signin,
        signinCallback,
        signout,
        signoutCallback,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

AuthProvider.propTypes = {
  children: PropTypes.node,
};
