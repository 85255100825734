import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu, AutoComplete, Select } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  TeamOutlined,
  HomeOutlined,
  FileTextOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { AuthContext } from '../contexts/authContext';
import { AccountContext } from '../contexts/accountContext';
import { PlaceParticipantContext } from '../contexts/placeParticipantContext';
import SubMenu from 'antd/lib/menu/SubMenu';

const { Header, Content, Footer, Sider } = Layout;

const renderTitle = (title: string) => {
  return (
    <span>
      {title}
      <a
        style={{ float: 'right' }}
        href="https://www.google.com/search?q=antd"
        target="_blank"
        rel="noopener noreferrer"
      >
        more
      </a>
    </span>
  );
};

const renderItem = (title: string, count: number) => {
  return {
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        <span>
          <UserOutlined /> {count}
        </span>
      </div>
    ),
  };
};

const LayoutScreen: React.FC = (props) => {
  const l = useLocation();
  const { signout } = useContext(AuthContext);
  const { placeParticipantList } = useContext(AccountContext);
  const { placeParticipantId, setPlaceParticipantId } = useContext(PlaceParticipantContext);

  const [collapsed, setCollapsed] = useState(false);

  const [value, setValue] = useState('');
  const [options, setOptions] = useState<
    {
      label: JSX.Element;
      options: {
        value: string;
        label: JSX.Element;
      }[];
    }[]
  >([]);

  const onSearch = () => {
    setOptions([
      {
        label: renderTitle('Libraries'),
        options: [renderItem('AntDesign', 10000), renderItem('AntDesign UI', 10600)],
      },
      {
        label: renderTitle('Solutions'),
        options: [renderItem('AntDesign UI FAQ', 60100), renderItem('AntDesign FAQ', 30010)],
      },
      {
        label: renderTitle('Articles'),
        options: [renderItem('AntDesign design language', 100000)],
      },
    ]);
  };
  const onSelect = (data: string) => {
    console.log('onSelect', data);
  };
  const onChange = (data: string) => {
    setValue(data);
  };

  const pp = placeParticipantList.find((x) => x.id === placeParticipantId);
  if (!pp) return <div>Error: PP not found</div>;

  const ppl = [...placeParticipantList].sort((a, b) => (a.place.name > b.place.name ? 1 : -1));

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          setCollapsed(collapsed);
          console.log(type);
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo" style={{ color: 'white', display: 'flex', flex: 1, alignItems: 'center' }}>
          <Select
            defaultValue={placeParticipantId ?? ''}
            style={{ width: '100%' }}
            onChange={(e) => {
              setPlaceParticipantId(e.toString());
              history.pushState(null, '', '/');
              location.href = location.href;
            }}
          >
            {ppl.map((x) => (
              <Select.Option key={x.id} value={x.id}>
                {x.place.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['/']}
          selectedKeys={[l.pathname]}
          style={{ flex: 1 }}
          defaultOpenKeys={['store', 'purchaser']}
        >
          <Menu.ItemGroup>
            <Menu.Item key="/" icon={<HomeOutlined />}>
              <Link to="/">Home</Link>
            </Menu.Item>
            <SubMenu key="store" icon={<UserOutlined />} title="Store">
              <Menu.Item key="/store/products" icon={<FileTextOutlined />}>
                <Link to="/store/products">Products</Link>
              </Menu.Item>
              <Menu.Item key="/store/purchasers" icon={<MenuOutlined />}>
                <Link to="/store/purchasers">Purchasers</Link>
              </Menu.Item>
              <Menu.Item key="/store/orders" icon={<MenuOutlined />}>
                <Link to="/store/orders">Orders</Link>
              </Menu.Item>
              <Menu.Item key="/store/discounts" icon={<MenuOutlined />}>
                <Link to="/store/discounts">Discounts</Link>
              </Menu.Item>
              <SubMenu key="store/stock" icon={<UserOutlined />} title="Stock">
                <Menu.Item key="/store/stock/items" icon={<FileTextOutlined />}>
                  <Link to="/store/stock/items">Items</Link>
                </Menu.Item>
                <Menu.Item key="/store/stock/changes" icon={<FileTextOutlined />}>
                  <Link to="/store/stock/changes">Changes</Link>
                </Menu.Item>
              </SubMenu>
            </SubMenu>
            <SubMenu key="purchaser" icon={<UserOutlined />} title="Purchaser">
              <Menu.Item key="/purchaser/stores" icon={<MenuOutlined />}>
                <Link to="/purchaser/stores">Stores</Link>
              </Menu.Item>
              <Menu.Item key="/puchaser/orders" icon={<MenuOutlined />}>
                <Link to="/purchaser/orders">Orders</Link>
              </Menu.Item>
            </SubMenu>
            {/* <Menu.Item key="/members" icon={<TeamOutlined />}>
              <Link to="/members">Members</Link>
            </Menu.Item> */}
          </Menu.ItemGroup>
          <Menu.ItemGroup>
            <Menu.Item
              key="/logout"
              icon={<TeamOutlined />}
              onClick={() => {
                signout();
              }}
            >
              Logout
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>
      </Sider>
      <Layout>
        <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => {
              setCollapsed(!collapsed);
            },
            style: { marginLeft: 20 },
          })}
          <AutoComplete
            value={value}
            options={options}
            style={{ width: 300, marginLeft: 20 }}
            onSelect={onSelect}
            onSearch={onSearch}
            onChange={onChange}
            placeholder="Search"
          />
        </Header>

        <Content className="site-layout" style={{ padding: '0 30px', marginTop: 0 }}>
          {props.children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>2020 © Biasa.io</Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutScreen;

LayoutScreen.propTypes = {
  children: PropTypes.node,
};
