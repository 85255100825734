import { Breadcrumb, Button, PageHeader, Pagination } from 'antd';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PlaceParticipantContext } from '../contexts/placeParticipantContext';
import { useDiscountListQuery } from '../Generated/graphql';

const ProductListScreen: React.FC = () => {
  const history = useHistory();

  const { placeParticipantId } = useContext(PlaceParticipantContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  if (!placeParticipantId) return <div>Error</div>;

  const data = useDiscountListQuery({
    variables: { skip: (currentPage - 1) * pageSize, take: pageSize },
  });

  function itemRender(
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>,
  ) {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  }

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Discounts</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/discounts')}
        title="Discounts"
        // subTitle="This is a subtitle"
        extra={[
          <Button key="1" type="primary">
            Add discount
          </Button>,
        ]}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        {data.loading ? (
          <div>...loading</div>
        ) : (
          <>
            <div style={{ marginBottom: 20 }}>
              {data.data?.discountList.list.map((x) => (
                <div key={x.id}>
                  <Link to={'/discounts/' + x.id}>{x.name}</Link>
                </div>
              ))}
            </div>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={data.data?.discountList.totalCount}
              itemRender={itemRender}
              onChange={(x) => {
                setCurrentPage(x);
              }}
              onShowSizeChange={(current, size) => setPageSize(size)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductListScreen;
