import { Tooltip } from 'antd';
import React from 'react';
import { usePlaceParticipantLazyQuery } from '../Generated/graphql';
import { dateTimeString, timeAgoString } from '../utils/date';

interface Props {
  by: string;
  at: string;
}

const ByAtToolTip: React.FC<Props> = (props) => {
  const [get, { data, loading }] = usePlaceParticipantLazyQuery();

  return (
    <Tooltip
      placement="top"
      title={
        <div>
          <div>{dateTimeString(props.at)}</div>
          <div>{loading ? '...loading' : data?.placeParticipant?.name}</div>
        </div>
      }
      onVisibleChange={() => {
        get({
          variables: {
            id: props.by,
          },
        });
      }}
    >
      {props.children && props.children}
      {!props.children && timeAgoString(props.at)}
    </Tooltip>
  );
};

export default ByAtToolTip;
