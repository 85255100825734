import { Breadcrumb, Col, Form, PageHeader, Row } from 'antd';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useStockChangeQuery } from '../Generated/graphql';
import { dateTimeString } from '../utils/date';

const StockChangeItemScreen: React.FC = () => {
  const { changeId } = useParams<{ changeId: string }>();
  const history = useHistory();

  const change = useStockChangeQuery({
    variables: {
      id: changeId,
    },
  });

  const x = change.data?.stockChange;

  if (!x) return null;

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/stock">Stock</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Change</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/stock')}
        title={change.loading ? '...loading' : change.data?.stockChange?.id ?? 'New'}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div className="site-layout-background" style={{ marginTop: 5, paddingLeft: 25 }}>
        {change.loading ? (
          <div>...loading</div>
        ) : (
          <div>
            <div>Change</div>
            <Row key={x.id} gutter={8} style={{ marginBottom: 10 }}>
              <Col span="4">
                <Link to={'./changes/' + x.id}>{dateTimeString(x.createdAt)}</Link>
                <div>{x.type}</div>
              </Col>

              <Col span="10">
                {x.inStockItemList.map((x) => (
                  <div key={x.id}>
                    <div>
                      <Link to={'/store/stock/items/' + x.id}>{x.type}</Link>
                    </div>
                    <div>
                      <Link to={'/store/products/' + x.product.id}>{x.product.name}</Link>
                    </div>
                  </div>
                ))}
              </Col>
              <Col span="10">
                {x.outStockItemList.map((x) => (
                  <div key={x.id}>
                    <div>
                      <Link to={'/store/stock/items/' + x.id}>{x.type}</Link>
                    </div>
                    <div>
                      <Link to={'/store/products/' + x.product.id}>{x.product.name}</Link>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default StockChangeItemScreen;
