import { Breadcrumb, Form, PageHeader } from 'antd';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDiscountQuery } from '../Generated/graphql';

const DiscountItemScreen: React.FC = () => {
  const { discountId } = useParams<{ discountId: string }>();
  const history = useHistory();

  const discount = useDiscountQuery({
    variables: {
      id: discountId,
    },
  });

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/discounts">Discounts</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Discount</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/discounts')}
        title={discount.loading ? '...loading' : discount.data?.discount?.name ?? 'New'}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div className="site-layout-background" style={{ marginTop: 5, paddingLeft: 25 }}>
        {discount.loading ? <div>...loading</div> : <div>Done</div>}
      </div>
    </div>
  );
};

export default DiscountItemScreen;
