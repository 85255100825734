import { Breadcrumb, Col, DatePicker, PageHeader, Radio, Row, Select, Tabs } from 'antd';
import React, { useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useOrderQuery, useUpdateOrderOrderDateMutation, useUpdateOrderStatusMutation } from '../Generated/graphql';
import moment from 'moment';
import { formatCurrency } from '../utils/currency';
import { CommonDataContext } from '../contexts/commonDataContext';

interface Props {
  type: 'store' | 'purchaser';
}

const ProductItemScreen: React.FC<Props> = (props) => {
  const { orderId } = useParams<{ orderId: string }>();
  const history = useHistory();

  const order = useOrderQuery({ variables: { id: orderId } });
  const [update] = useUpdateOrderStatusMutation();

  const { orderStatusTypeList } = useContext(CommonDataContext);

  const [updateOrderOrderDate] = useUpdateOrderOrderDateMutation();

  if (orderId === 'new') return null;

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/purchaser/orders">Orders</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            order.refetch();
          }}
        >
          Order
        </Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/purchaser/orders')}
        title={order.loading ? '...loading' : order.data?.orderById?.orderId}
        // subTitle="This is a subtitle"
        // extra={[
        //   <Button key="1" type="primary">
        //     Save
        //   </Button>,
        //   <DropdownMenu key="more" />,
        // ]}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div className="site-layout-background" style={{ marginTop: 5, paddingTop: 10, paddingLeft: 25 }}>
        {order.loading ? (
          <div>...loading</div>
        ) : (
          <Tabs defaultActiveKey="1" tabBarGutter={30}>
            <Tabs.TabPane tab="General" key="1">
              {!order.loading && (
                <div style={{ padding: 10 }}>
                  <Row>
                    <Col>
                      <div style={{ fontWeight: 'bold' }}>{order.data?.orderById.storeContact?.name}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DatePicker
                        value={moment(order.data?.orderById.orderDate)}
                        onChange={(e) => {
                          updateOrderOrderDate({
                            variables: {
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              orderId: order.data!.orderById.id,
                              orderDate: e?.toISOString(),
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              type: order.data!.orderById.type,
                            },
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span="2">
                      <Select
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        value={order.data!.orderById.type}
                        options={[
                          { label: 'Invoice', value: 'invoice' },
                          { label: 'Consignment', value: 'consignment' },
                        ]}
                        onChange={(e) => {
                          updateOrderOrderDate({
                            variables: {
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              orderId: order.data!.orderById.id,
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              orderDate: order.data!.orderById.orderDate,
                              type: e as string,
                            },
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio.Group
                        value={order.data!.orderById.orderStatus.type.id}
                        buttonStyle="solid"
                        onChange={(e) => {
                          console.log('x', e.target.value);
                          update({
                            variables: {
                              orderId: order.data!.orderById.id,
                              statusTypeId: e.target.value,
                            },
                          });
                        }}
                      >
                        {orderStatusTypeList.map((x) => (
                          <Radio.Button key={x.id} value={x.id}>
                            {x.title}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Col>
                  </Row>
                </div>
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Items" key="2">
              {!order.loading && (
                <div style={{ padding: 10 }}>
                  <Row gutter={16}>
                    <Col xs={19}>Product</Col>
                    <Col xs={2} style={{ textAlign: 'right' }}>
                      Quantity
                    </Col>
                    <Col xs={3} style={{ textAlign: 'right' }}>
                      Total
                    </Col>
                  </Row>
                  {order.data?.orderById.itemList.map((x) => (
                    <Row key={x.id} gutter={16}>
                      <Col xs={19}>
                        {props.type === 'purchaser' && (
                          <Link to={'/store/products/' + x.purchaserProduct?.id} target="_blank">
                            {x.purchaserProduct?.name}
                          </Link>
                        )}
                        {props.type === 'store' && (
                          <Link to={'/store/products/' + x.storeProduct?.id} target="_blank">
                            {x.storeProduct?.name}
                          </Link>
                        )}
                      </Col>
                      <Col xs={2} style={{ textAlign: 'right' }}>
                        {x.quantity}
                      </Col>
                      <Col xs={3} style={{ textAlign: 'right' }}>
                        {formatCurrency(x.totalNet)}
                      </Col>
                    </Row>
                  ))}
                  <Row gutter={16} style={{ marginTop: 10 }}>
                    <Col xs={{ offset: 18, span: 3 }} style={{ textAlign: 'right' }}>
                      Total
                    </Col>
                    <Col xs={{ span: 3 }} style={{ textAlign: 'right' }}>
                      {formatCurrency(order.data?.orderById.totalNet)}
                    </Col>
                  </Row>
                </div>
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Files" key="3">
              {!order.loading && (
                <div>
                  {order.data?.orderById.fileList.map((x) => (
                    <div key={x.id} style={{ paddingBottom: 20 }}>
                      <Row gutter={16}>
                        <Col>{x.fileTypeId}</Col>
                      </Row>
                      {x.fileExtension === '.pdf' ? (
                        <iframe src={x.url} id="iframe-pdf" width="100%" height="400px"></iframe>
                      ) : (
                        <img src={x.url}></img>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </Tabs.TabPane>
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default ProductItemScreen;
