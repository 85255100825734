import { Breadcrumb, Button, Col, Input, PageHeader, Pagination, Row } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PlaceParticipantContext } from '../contexts/placeParticipantContext';
import { usePurchaserContactListQuery } from '../Generated/graphql';
import debounce from 'lodash/debounce';

const PurchaserContactListScreen: React.FC = () => {
  const history = useHistory();
  const { placeParticipantId } = useContext(PlaceParticipantContext);

  if (!placeParticipantId) return <div>Error</div>;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [query, setQuery] = useState('');

  const data = usePurchaserContactListQuery({
    variables: { query, orderBy: 'name', skip: (currentPage - 1) * pageSize, take: pageSize },
  });

  const debouncedSearch = useCallback(
    debounce((nextValue) => setQuery(nextValue), 500),
    [],
  );

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Purchasers</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/')}
        title="Purchasers"
        // subTitle="This is a subtitle"
        extra={[
          <Button key="1" type="primary">
            Add purchaser
          </Button>,
        ]}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div
        style={{
          backgroundColor: 'white',
          marginTop: 5,
          marginBottom: 5,
          padding: 10,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div></div>
        <div>
          <Input placeholder={'Search'} onChange={(q) => debouncedSearch(q.target.value)} />
        </div>
      </div>

      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        {data.loading ? (
          <div>...loading</div>
        ) : (
          <>
            <div style={{ marginBottom: 20 }}>
              {data.data?.purchaserContactList.contactList.map((x) => (
                <Row key={x.id}>
                  <Col xs={4}>
                    <Link to={'/store/purchasers/' + x.id}>{x.name}</Link>
                  </Col>
                  <Col>{x.type}</Col>
                </Row>
              ))}
            </div>
            <Pagination
              current={currentPage}
              defaultPageSize={pageSize}
              total={data.data?.purchaserContactList.totalCount}
              itemRender={itemRender}
              onChange={(x) => {
                setCurrentPage(x);
              }}
              onShowSizeChange={(current, size) => setPageSize(size)}
            />
          </>
        )}
      </div>
    </div>
  );

  function itemRender(
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>,
  ) {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  }
};

export default PurchaserContactListScreen;
