import { Badge, Breadcrumb, Button, Col, Input, PageHeader, Pagination, Row, Select } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { PlaceParticipantContext } from '../contexts/placeParticipantContext';
import { OrderPageInfo, usePurchaserOrderListQuery, useStoreOrderListQuery } from '../Generated/graphql';
import { dateToString } from '../utils/date';
import ByAtToolTip from '../components/byAtToolTip';
import { formatCurrency } from '../utils/currency';
import debounce from 'lodash/debounce';
interface Props {
  type: 'store' | 'purchaser';
}

const OrderListScreen: React.FC<Props> = (props) => {
  const history = useHistory();
  const { placeParticipantId } = useContext(PlaceParticipantContext);

  const { search } = useLocation();
  const u = new URLSearchParams(search);

  if (!placeParticipantId) return <div>Error</div>;

  const [currentPage, setCurrentPage] = useState(+(u.get('page') ?? 1));
  const [orderBy, setOrderBy] = useState(u.get('order-by') ?? 'order-date');
  const [pageSize, setPageSize] = useState(100);
  const [query, setQuery] = useState('');

  let ol: OrderPageInfo | undefined = undefined;
  let da: Partial<{ loading: boolean; refetch: any }> = {};

  const isPurchaser = props.type === 'purchaser';

  if (isPurchaser) {
    const data = usePurchaserOrderListQuery({
      variables: { query, skip: (currentPage - 1) * pageSize, take: pageSize, orderBy },
    });
    da = data;
    ol = data.data?.orders.purchaser.orderList as OrderPageInfo;
  } else {
    const data2 = useStoreOrderListQuery({
      variables: { query, skip: (currentPage - 1) * pageSize, take: pageSize, orderBy },
    });
    da = data2;
    ol = data2.data?.orders.store.orderList as OrderPageInfo;
  }

  const debouncedSearch = useCallback(
    debounce((nextValue) => setQuery(nextValue), 500),
    [],
  );

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to="/purchaser/orders"
            onClick={() => {
              da.refetch();
            }}
          >
            Orders
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/store/orders')}
        title="Orders"
        // subTitle="This is a subtitle"
        extra={[
          props.type === 'purchaser' && (
            <Button key="1" type="primary" onClick={() => history.push('/purchaser/orders/new')}>
              Add order
            </Button>
          ),
        ]}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div
        style={{
          backgroundColor: 'white',
          marginTop: 5,
          marginBottom: 5,
          padding: 10,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div></div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Input placeholder={'Search'} onChange={(q) => debouncedSearch(q.target.value)} style={{ marginRight: 10 }} />
          <Select
            defaultValue={'order-date'}
            style={{ width: '100%' }}
            onChange={(e) => {
              setOrderBy(e);
            }}
          >
            <Select.Option key="order-date" value="order-date">
              Order date
            </Select.Option>
            <Select.Option key="created-date" value="created-date">
              Created date
            </Select.Option>
          </Select>
        </div>
      </div>

      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        {da.loading ? (
          <div>...loading</div>
        ) : (
          <>
            <div style={{ marginBottom: 20 }}>
              {ol?.items.map((x) => (
                <Row key={x.id} gutter={8}>
                  <Col xs={2}>
                    <Link to={'/' + props.type + '/orders/' + x.id}>{x.orderId}</Link>
                  </Col>
                  <Col xs={3}>
                    {isPurchaser ? (
                      <Link to={`/${props.type}/stores/${x.storeContact?.id}`}>{x.storeContact?.name}</Link>
                    ) : (
                      <Link to={`/${props.type}/purchasers/${x.purchaserContact?.id}`}>{x.purchaserContact?.name}</Link>
                    )}
                  </Col>
                  <Col xs={3} style={{ textAlign: 'right' }}>
                    {formatCurrency(x.totalNet)}
                  </Col>
                  <Col xs={3}>{x.type === 'invoice' ? 'Purchase' : 'Consignment'}</Col>
                  <Col xs={4}>
                    <ByAtToolTip by={x.orderStatus.createdBy} at={x.orderStatus.createdAt}>
                      {x.orderStatus.name}
                    </ByAtToolTip>
                  </Col>
                  <Col xs={4}>
                    <ByAtToolTip by={x.paymentStatus.createdBy} at={x.paymentStatus.createdAt}>
                      <Badge
                        status={
                          x.paymentStatus.statusId === 'unpaid'
                            ? 'error'
                            : x.paymentStatus.statusId === 'unconfirmed'
                            ? 'warning'
                            : x.paymentStatus.statusId === 'paid'
                            ? 'success'
                            : 'default'
                        }
                        text={x.paymentStatus.name}
                      ></Badge>
                    </ByAtToolTip>
                  </Col>

                  <Col xs={4}>{dateToString(x.orderDate)}</Col>
                </Row>
              ))}
            </div>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={ol?.totalCount}
              itemRender={itemRender}
              onChange={(x) => {
                const params = new URLSearchParams();
                if (x) {
                  params.append('page', x.toString());
                } else {
                  params.delete('page');
                }
                history.push({ search: params.toString() });
                setCurrentPage(x);
              }}
              onShowSizeChange={(current, size) => setPageSize(size)}
            />
          </>
        )}
      </div>
    </div>
  );

  function itemRender(
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>,
  ) {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  }
};

export default OrderListScreen;
