import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Upload,
  InputNumber,
  Row,
  Col,
  Breadcrumb,
  PageHeader,
  AutoComplete,
  DatePicker,
} from 'antd';
import { UploadOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useCreatePurchaseOrderMutation, useStoreContactListQuery } from '../Generated/graphql';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { Link, useHistory } from 'react-router-dom';
import ProductAutoCompleteComponent from './ProductAutoCompleteComponent';
import { FormListFieldData } from 'antd/lib/form/FormList';

const ManualOrderItemScreen: React.FC = () => {
  const history = useHistory();
  const [createPurchaseOrderMutation, { data, loading, error }] = useCreatePurchaseOrderMutation({});
  const storeContactList = useStoreContactListQuery();

  const [d, setD] = useState('');
  const [originalFileName, setOriginalFileName] = useState('');
  const [isPdf, setIsPdf] = useState(false);
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    values.items.forEach((element) => {
      delete element.productName;
    });

    const r = await createPurchaseOrderMutation({
      variables: {
        order: {
          contactId: values.contactId,
          originalFileName: originalFileName,
          fileData: d,
          documentDate: values.documentDate.toISOString(),
          type: values.type,
          itemList: values.items,
        },
      },
    });

    if (r.errors && r.errors.length > 0) {
    } else {
      history.push('/purchaser/orders/' + r.data?.createPurchaseOrder.id);
    }
  };

  const handleChange = () => {
    //form.setFieldsValue({ sights: [] });
  };

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/purchaser/orders">Orders</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Order</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/stores')}
        title="New purchaser order"
        // title={product.loading ? '...loading' : product.data?.product?.name}
        // subTitle="This is a subtitle"
        // extra={[
        //   <Button key="1" type="primary">
        //     Save
        //   </Button>,
        //   <DropdownMenu key="more" />,
        // ]}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div style={{ backgroundColor: 'white', padding: '20px' }}>
        <div>
          <Upload
            maxCount={1}
            beforeUpload={(file: File) => {
              setOriginalFileName(file.name);
              setIsPdf(file.name.indexOf('.pdf') !== -1);
              toBase64(file).then((x) => setD(x as string));

              return false;
            }}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Select file</Button>
          </Upload>
        </div>
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          {isPdf ? <iframe src={d} id="iframe-pdf" width="100%" height="400px"></iframe> : <img src={d}></img>}
        </div>
        <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
          <Form.Item name="contactId" label="Store" rules={[{ required: true, message: 'Missing store' }]}>
            <Select
              options={storeContactList.data?.storeContactList?.contactList.map((x) => ({
                label: x.name,
                value: x.id,
              }))}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item name="documentDate" label="Document date" rules={[{ required: true, message: 'Missing date' }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Missing type' }]}>
            <Select
              options={[
                { label: 'Invoice', value: 'invoice' },
                { label: 'Consignment', value: 'consignment' },
              ]}
              onChange={handleChange}
            />
          </Form.Item>
          <>
            <Row gutter={8} style={{ marginBottom: 2 }}>
              <Col span="8" style={{ marginLeft: 2 }}>
                Product
              </Col>
              <Col span="5" style={{ marginLeft: 2 }}>
                Quantity
              </Col>
              <Col span="5" style={{ marginLeft: 2 }}>
                Tax
              </Col>
              <Col span="5" style={{ marginLeft: 2 }}>
                Total
              </Col>
              <Col span="1" style={{ marginLeft: 2 }}></Col>
            </Row>
            <Form.List
              name="items"
              rules={[
                {
                  message: '',
                  validator: (rule: RuleObject, value: StoreValue) => {
                    return new Promise<void>((r, re) => {
                      console.log(form.getFieldsValue());
                      console.log('validate', value);

                      value.forEach((element) => {
                        element.taxAmount = element.taxAmount ?? 0;
                      });

                      if (value.length > 0) r();
                      re('Items missing');
                    });
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  <Row gutter={8}>
                    {fields.map((field: FormListFieldData) => (
                      <>
                        <Col span="8">
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.contactId !== curValues.contactId || prevValues.items !== curValues.items
                            }
                          >
                            {() => <ProductAutoCompleteComponent form={form} field={field} />}
                          </Form.Item>
                        </Col>
                        <Col span="5">
                          <Form.Item
                            style={{ marginBottom: 4 }}
                            {...field}
                            name={[field.name, 'quantity']}
                            fieldKey={[field.fieldKey, 'quantity']}
                            rules={[{ required: true, message: 'Missing quantity' }]}
                          >
                            <InputNumber defaultValue={0} min={1} style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col span="5">
                          <Form.Item
                            style={{ marginBottom: 4 }}
                            {...field}
                            name={[field.name, 'taxAmount']}
                            fieldKey={[field.fieldKey, 'taxAmount']}
                            rules={[]}
                          >
                            <InputNumber defaultValue={0} style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col span="5">
                          <Form.Item
                            style={{ marginBottom: 4 }}
                            {...field}
                            name={[field.name, 'totalAmount']}
                            fieldKey={[field.fieldKey, 'totalAmount']}
                            rules={[{ required: true, message: 'Missing total' }]}
                          >
                            <InputNumber defaultValue={0} style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col span="1">
                          <MinusCircleOutlined onClick={() => remove(field.name)} style={{ marginTop: 10 }} />
                        </Col>
                      </>
                    ))}
                  </Row>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ marginTop: 10 }}>
                      Add item
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ManualOrderItemScreen;
