import { Button, Form, Input, Modal, Select } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateContactMutation } from '../Generated/graphql';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

interface Props {
  //
  visible: boolean;
  onClose: () => void;
}

const StoreContactListNewModel: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [createContactMutation, { loading }] = useCreateContactMutation();

  const onFinish = async (values: { name: string; placeTypeId: string }) => {
    const r = await createContactMutation({
      variables: {
        name: values.name,
        placeTypeId: values.placeTypeId,
      },
    });

    history.push('/purchaser/stores/' + r.data?.createContact.id);
  };
  const onFinishFailed = () => {
    //console.log(values);
  };

  return (
    <Modal
      title="Create new store"
      centered
      visible={props.visible}
      footer={null}
      onCancel={() => {
        !loading && props.onClose();
      }}
    >
      <Form
        {...layout}
        form={form}
        initialValues={{ placeTypeId: 'business' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="placeTypeId" label="Purchaser Type">
          <Select>
            <Select.Option key={'private'} value={'private'}>
              Private
            </Select.Option>
            <Select.Option key={'business'} value={'business'}>
              Business
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{ marginRight: '8px' }} loading={loading}>
            Create
          </Button>
          <Button htmlType="button" onClick={props.onClose} loading={loading}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StoreContactListNewModel;
