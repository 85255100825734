import React, { useContext, useEffect } from 'react';
import { AuthContext } from './contexts/authContext';

const SignoutCallbackHandler = (): JSX.Element => {
  const { signoutCallback } = useContext(AuthContext);

  useEffect(() => {
    signoutCallback();
  }, [signoutCallback]);

  return <div>...logging you out</div>;
};

export default SignoutCallbackHandler;
