import { Breadcrumb, Col, Form, PageHeader, Row } from 'antd';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useStockItemQuery } from '../Generated/graphql';
import { formatCurrency } from '../utils/currency';
import { dateTimeString } from '../utils/date';

const StockItemItemScreen: React.FC = () => {
  const { itemId } = useParams<{ itemId: string }>();
  const history = useHistory();

  const item = useStockItemQuery({
    variables: {
      id: itemId,
    },
  });

  const x = item.data?.stockItem;

  if (!x) return null;

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/stock">Stock</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Stock</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/stock')}
        title={item.loading ? '...loading' : item.data?.stockItem?.id ?? 'New'}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div className="site-layout-background" style={{ marginTop: 5, paddingLeft: 25 }}>
        {item.loading ? (
          <div>...loading</div>
        ) : (
          <Row key={x.id} gutter={8} style={{ padding: 10, borderBottom: '1px solid lightgray' }}>
            <Col span="14">
              <div>
                <Link to={'./items/' + x.id}>{dateTimeString(x.createdAt)}</Link>
              </div>
              <div>
                <Link to={'/store/products/' + x.product.id}>{x.product.name}</Link>
              </div>
              <div>{x.type}</div>
            </Col>
            <Col span="7" style={{ textAlign: 'right', whiteSpace: 'normal' }}>
              <div>{dateTimeString(x.inDateTime, '-')}</div>
              <div>
                {x.inOrder && <Link to={'/purchaser/orders/' + x.inOrder.id}>Order {x.inOrder.orderId}</Link>}
                {x.inStockChange && (
                  <Link to={'/store/stock/changes/' + x.inStockChange.id}>Change ({x.inStockChange.type})</Link>
                )}
                {x.inStockChange?.inStockItemList.map((x) => (
                  <div key={x.id}>
                    <Link to={'/store/stock/items/' + x.id}>Prev</Link>
                  </div>
                ))}
              </div>
              <div>{formatCurrency(x.inPriceNet, '-')}</div>
            </Col>
            <Col span="3" style={{ textAlign: 'right' }}>
              <div>{dateTimeString(x.outDateTime, '-')}</div>
              {x.outOrder && <Link to={'/store/orders/' + x.outOrder.id}>Order {x.outOrder.orderId}</Link>}
              {x.outStockChange && (
                <Link to={'/store/stock/changes/' + x.outStockChange.id}>Change ({x.outStockChange.type})</Link>
              )}
              {x.outStockChange?.outStockItemList.map((x) => (
                <div key={x.id}>
                  <Link to={'/store/stock/items/' + x.id}>Next</Link>
                </div>
              ))}
              <div>{formatCurrency(x.outPriceNet, '-')}</div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default StockItemItemScreen;
