import React, { useContext, useEffect } from 'react';
import LoadingComponent from './components/loadingComponent';
import { AuthContext } from './contexts/authContext';

const RenewCallbackHandler = (): JSX.Element => {
  // const { signinCallback } = useContext(AuthContext);

  // useEffect(() => {
  //   signinCallback();
  // }, [signinCallback]);
  console.log('renew');

  return <LoadingComponent>...logging you in</LoadingComponent>;
};

export default RenewCallbackHandler;
