import React, { useContext, useEffect } from 'react';
import LoadingComponent from './components/loadingComponent';
import { AuthContext } from './contexts/authContext';

const SigninCallbackHandler = (): JSX.Element => {
  const { signinCallback } = useContext(AuthContext);

  useEffect(() => {
    signinCallback();
  }, [signinCallback]);

  return <LoadingComponent>...logging you in</LoadingComponent>;
};

export default SigninCallbackHandler;
