import { AutoComplete, Form, FormInstance, Input } from 'antd';
import { OmitProps } from 'antd/lib/transfer/ListBody';
import React, { useEffect, useState } from 'react';
import { useProductListLazyQuery } from '../Generated/graphql';

const mockVal = (str: string, repeat = 1) => ({
  value: str.repeat(repeat),
});

interface Props {
  form: FormInstance<any>;
  field: any;
}

const ProductAutoCompleteComponent: React.FC<Props> = (props) => {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<{ id: string; value: string }[]>([]);

  const [search, { data, loading }] = useProductListLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    //
    console.log('d', data);
    if (data) {
      setOptions(
        data.productList.products.map((x) => ({
          id: x.id,
          value: x.name,
        })),
      );
    }
  }, [data]);

  const onSearch = (searchText: string) => {
    search({
      variables: {
        query: searchText,
        take: 10,
      },
    });
  };
  const onSelect = (value: string, option) => {
    const fv = props.form.getFieldsValue();
    fv.items[props.field.fieldKey].productId = option.id;
    props.form.setFieldsValue(fv);
  };

  const onBlur = () => {
    const fv = props.form.getFieldsValue();
    const f = fv.items[props.field.fieldKey];
    if (f) {
      if (!f.productId) f.productName = '';
    }
    props.form.setFieldsValue(fv);
  };

  return (
    <Form.Item
      style={{ marginBottom: 4 }}
      {...props.field}
      name={[props.field.name, 'productName']}
      fieldKey={[props.field.fieldKey, 'productName']}
      rules={[{ required: true, message: 'Missing product' }]}
    >
      <AutoComplete options={options} onSelect={onSelect} onSearch={onSearch} placeholder="" onBlur={onBlur}>
        <Input required={true} />
      </AutoComplete>
    </Form.Item>
  );
};

export default ProductAutoCompleteComponent;
