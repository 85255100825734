import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import React from 'react';
import { useCreateStockChangeMutation } from '../Generated/graphql';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

interface Props {
  visible: boolean;
  onClose: () => void;
}

const StoreStockChangeNewModal: React.FC<Props> = (props) => {
  const [form] = Form.useForm();

  const [createStockChangeMutation, { loading }] = useCreateStockChangeMutation();

  const onFinish = async (values: any) => {
    const r = await createStockChangeMutation({
      variables: {
        stockChange: {
          type: values.type,
          changeDate: values.changeDate,
          inProductIdList: values.inProductIdList,
          outProductIdList: values.outProductIdList,
          quantity: values.quantity,
          description: values.description ?? '',
        },
      },
    });

    if (r.errors && r.errors.length > 0) {
    } else {
      console.log('StockChanges created');
    }
  };
  const onFinishFailed = () => {
    //console.log(values);
  };

  return (
    <Modal
      title="Create new store"
      centered
      visible={props.visible}
      footer={null}
      onCancel={() => {
        !loading && props.onClose();
      }}
    >
      <Form
        {...layout}
        form={form}
        initialValues={{ type: 'combine' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select>
            <Select.Option key={'move'} value={'move'}>
              Move
            </Select.Option>
            <Select.Option key={'combine'} value={'combine'}>
              Combine
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="changeDate" label="Change date" rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>

        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{ marginRight: '8px' }} loading={loading}>
            Create
          </Button>
          <Button htmlType="button" onClick={props.onClose} loading={loading}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StoreStockChangeNewModal;
