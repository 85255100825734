const development = {
  REACT_APP_AUTHORITY_URL: 'https://laoicom.ap.ngrok.io',
  REACT_APP_GRAPHQL_URL: 'http://l-admin-gqlg-oicom.ap.ngrok.io',
  REACT_APP_APPINSIGHTS_KEY: 'd60c8cdb-6bda-4848-bc5b-623bb842f357',
};

const staging = {
  REACT_APP_AUTHORITY_URL: 'https://s.account.orderindo.com',
  REACT_APP_GRAPHQL_URL: 'https://s.admin.gqlg.orderindo.com',
  REACT_APP_APPINSIGHTS_KEY: 'b84310f3-ef28-4ac9-914f-9cbd2293d596',
};

const production = {
  REACT_APP_AUTHORITY_URL: 'https://account.orderindo.com',
  REACT_APP_GRAPHQL_URL: 'https://admin.gqlg.orderindo.com',
  REACT_APP_APPINSIGHTS_KEY: 'e066e91c-875f-4ef9-81fc-6c0207d1f2b2',
};

let config = development;

let env = 'development';

if (process.env.NODE_ENV === 'production') {
  if (window.location.hostname === 'admin.biasa.io') {
    env = 'production';
    config = production;
  } else {
    env = 'staging';
    config = staging;
  }
}

export default {
  Environment: env,
  BuildNumber: process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : 'NA',
  ...config,
};
