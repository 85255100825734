import React from 'react';

const LoadingComponent: React.FC = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <div>{props.children}</div>
    </div>
  );
};

export default LoadingComponent;
