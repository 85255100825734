import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AuthContext } from './authContext';
import { ConfigContext } from './configContext';
import { PlaceParticipantContext } from './placeParticipantContext';
import LoadingComponent from '../components/loadingComponent';

const GraphQLContext = React.createContext({});

const GraphQLProvider: React.FC = (props) => {
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);

  const { user } = useContext(AuthContext);
  const { placeParticipantId } = useContext(PlaceParticipantContext);
  const { graphqlUri } = useContext(ConfigContext);

  useEffect(() => {
    setLoading(true);
    const httpLink = createHttpLink({
      uri: graphqlUri + '/api/graphql/admin',
      //fetch: createNetworkInterface(graphqlUri + '/api/graphql/admin'),
    });

    const authLink = setContext((_, { headers }) => {
      const token = user?.access_token;
      console.log('ppid', placeParticipantId);
      return {
        headers: {
          ...headers,
          'place-participant-id': placeParticipantId,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

    setClient(client);
    setLoading(false);
  }, [user]);

  if (loading) return <LoadingComponent>...loading auth</LoadingComponent>;

  if (!client) return <div>...loading auth failed</div>;

  return (
    <GraphQLContext.Provider
      value={{
        loading,
      }}
    >
      <ApolloProvider client={client}>{props.children}</ApolloProvider>
    </GraphQLContext.Provider>
  );
};

export { GraphQLContext, GraphQLProvider };

GraphQLProvider.propTypes = {
  children: PropTypes.node,
};
