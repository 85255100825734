import React from 'react';

interface ConfigContextType {
  authorityUri: string;
  graphqlUri: string;
}

const ConfigContext = React.createContext<ConfigContextType>({} as ConfigContextType);

export { ConfigContext };
