import { Breadcrumb, Button, Input, PageHeader, Pagination } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PlaceParticipantContext } from '../contexts/placeParticipantContext';
import { useStoreContactListQuery } from '../Generated/graphql';
import StoreContactListNewModel from './StoreContactListNewModal';
import debounce from 'lodash/debounce';

const StoreContactListScreen: React.FC = () => {
  const history = useHistory();
  const { placeParticipantId } = useContext(PlaceParticipantContext);

  const [newModalVisible, setNewModelVisible] = useState(false);

  if (!placeParticipantId) return <div>Error</div>;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [query, setQuery] = useState('');

  const data = useStoreContactListQuery({
    variables: { query, orderBy: 'name', skip: (currentPage - 1) * pageSize, take: pageSize },
  });

  const debouncedSearch = useCallback(
    debounce((nextValue) => setQuery(nextValue), 500),
    [],
  );

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Stores</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/stores')}
        title="Stores"
        // subTitle="This is a subtitle"
        extra={[
          <Button key="1" type="primary" onClick={() => setNewModelVisible(true)}>
            Add store
          </Button>,
        ]}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div
        style={{
          backgroundColor: 'white',
          marginTop: 5,
          marginBottom: 5,
          padding: 10,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div></div>
        <div>
          <Input placeholder={'Search'} onChange={(q) => debouncedSearch(q.target.value)} />
        </div>
      </div>

      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        {data.loading ? (
          <div>...loading</div>
        ) : (
          <>
            <div style={{ marginBottom: 20 }}>
              {data.data?.storeContactList.contactList.map((x) => (
                <div key={x.id}>
                  <Link to={'/purchaser/stores/' + x.id}>{x.name}</Link>
                </div>
              ))}
            </div>
            <Pagination
              current={currentPage}
              defaultPageSize={pageSize}
              total={data.data?.storeContactList.totalCount}
              itemRender={itemRender}
              onChange={(x) => {
                setCurrentPage(x);
              }}
              onShowSizeChange={(current, size) => setPageSize(size)}
            />
          </>
        )}
      </div>
      <StoreContactListNewModel visible={newModalVisible} onClose={() => setNewModelVisible(false)} />
    </div>
  );

  function itemRender(
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>,
  ) {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  }
};

export default StoreContactListScreen;
