import React, { useContext } from 'react';
import './App.css';
import { ConfigContext } from './contexts/configContext';
import { AuthContext, AuthProvider } from './contexts/authContext';
import LayoutScreen from './screens/Layout';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import SigninCallbackHandler from './SigninCallbackHandler';
import SignoutCallbackHandler from './SignoutCallbackHandler';
import { PlaceParticipantProvider } from './contexts/placeParticipantContext';
import { GraphQLProvider } from './contexts/graphQLContext';
import HomeScreen from './screens/HomeScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProductItemScreen from './screens/ProductItemScreen';
import { AccountProvider } from './contexts/accountContext';
import { CommonDataProvider } from './contexts/commonDataContext';
import PurchaserContactItemScreen from './screens/PurchaserContactItemScreen';
import PurchaserContactListScreen from './screens/PurchaserContactListScreen';
import StoreContactListScreen from './screens/StoreContactListScreen';
import StoreContactItemScreen from './screens/StoreContactItemScreen';
import OrderListScreen from './screens/OrderListScreen';
import ManualOrderItemScreen from './screens/ManualOrderItemScreen';
import config from './config';
import DiscountListScreen from './screens/DiscountListScreen';
import DiscountItemScreen from './screens/DiscountItemScreen';
import { Button } from 'antd';
import OrderItemScreen from './screens/OrderItemScreen';
import RenewCallbackHandler from './RenewCallbackHandler';
import StockItemListScreen from './screens/StockItemListScreen';
import StockItemItemScreen from './screens/StockItemItemScreen';
import StockChangeListScreen from './screens/StockChangeListScreen';
import StockChangeItemScreen from './screens/StockChangeItemScreen';

function App(): JSX.Element {
  if (config.Environment !== 'development' && location.protocol !== 'https:')
    location.replace(`https:${location.href.substring(location.protocol.length)}`);

  const customHistory = createBrowserHistory();

  return (
    <Router history={customHistory}>
      <ConfigContext.Provider
        value={{
          authorityUri: config.REACT_APP_AUTHORITY_URL,
          graphqlUri: config.REACT_APP_GRAPHQL_URL,
        }}
      >
        <AuthProvider>
          <App2></App2>
        </AuthProvider>
      </ConfigContext.Provider>
    </Router>
  );
}

const App2: React.FC = () => {
  const signinCallbackRoute = (
    <Route exact={true} path="/callback">
      <SigninCallbackHandler></SigninCallbackHandler>
    </Route>
  );
  const renewCallbackRoute = (
    <Route exact={true} path="renew">
      <RenewCallbackHandler></RenewCallbackHandler>
    </Route>
  );
  const signoutCallbackRoute = (
    <Route exact={true} path="/callbackSignout">
      <SignoutCallbackHandler></SignoutCallbackHandler>
    </Route>
  );

  const { user, signin } = useContext(AuthContext);

  if (user) {
    return (
      <PlaceParticipantProvider>
        <GraphQLProvider>
          <AccountProvider>
            <CommonDataProvider>
              <LayoutScreen>
                <Switch>
                  <Route path="/store">
                    <Route path="/store/products" exact>
                      <ProductListScreen></ProductListScreen>
                    </Route>
                    <Route path="/store/products/:productId">
                      <ProductItemScreen></ProductItemScreen>
                    </Route>
                    <Route path="/store/purchasers" exact>
                      <PurchaserContactListScreen></PurchaserContactListScreen>
                    </Route>
                    <Route path="/store/purchasers/:contactId">
                      <PurchaserContactItemScreen></PurchaserContactItemScreen>
                    </Route>
                    <Route path="/store/discounts" exact>
                      <DiscountListScreen></DiscountListScreen>
                    </Route>
                    <Route path="/store/discounts/:discountId">
                      <DiscountItemScreen></DiscountItemScreen>
                    </Route>
                    <Route path="/store/orders" exact>
                      <OrderListScreen type={'store'}></OrderListScreen>
                    </Route>
                    <Route path="/store/orders/:orderId">
                      <OrderItemScreen type="store"></OrderItemScreen>
                    </Route>
                    <Route path="/store/stock/items" exact>
                      <StockItemListScreen></StockItemListScreen>
                    </Route>
                    <Route path="/store/stock/items/:itemId">
                      <StockItemItemScreen></StockItemItemScreen>
                    </Route>
                    <Route path="/store/stock/changes" exact>
                      <StockChangeListScreen />
                    </Route>
                    <Route path="/store/stock/changes/:changeId">
                      <StockChangeItemScreen />
                    </Route>
                  </Route>
                  <Route path="/purchaser">
                    <Route path="/purchaser/stores" exact>
                      <StoreContactListScreen></StoreContactListScreen>
                    </Route>
                    <Route path="/purchaser/stores/:contactId">
                      <StoreContactItemScreen></StoreContactItemScreen>
                    </Route>
                    <Route path="/purchaser/orders" exact>
                      <OrderListScreen type={'purchaser'}></OrderListScreen>
                    </Route>
                    <Route path="/purchaser/orders/new" exact>
                      <ManualOrderItemScreen></ManualOrderItemScreen>
                    </Route>
                    <Route path="/purchaser/orders/:orderId">
                      <OrderItemScreen type="purchaser"></OrderItemScreen>
                    </Route>
                  </Route>
                  <Route path="/" exact>
                    <HomeScreen></HomeScreen>
                  </Route>
                </Switch>
              </LayoutScreen>
            </CommonDataProvider>
          </AccountProvider>
        </GraphQLProvider>
      </PlaceParticipantProvider>
    );
  } else {
    return (
      <Switch>
        {signinCallbackRoute}
        {renewCallbackRoute}
        {signoutCallbackRoute}
        <Route path="/">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Biasa Admin</div>
            <Button type="primary" onClick={() => signin()}>
              Login
            </Button>
          </div>
        </Route>
      </Switch>
    );
  }
};

export default App;
