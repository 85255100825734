import { Breadcrumb, Button, Col, PageHeader, Pagination, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PlaceParticipantContext } from '../contexts/placeParticipantContext';
import { useStockChangeListQuery } from '../Generated/graphql';
import { dateTimeString } from '../utils/date';
import StoreStockChangeNewModal from './StoreStockChangeNewModal';

const StockChangeListScreen: React.FC = () => {
  const history = useHistory();

  const { placeParticipantId } = useContext(PlaceParticipantContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [newModalVisible, setNewModelVisible] = useState(false);

  if (!placeParticipantId) return <div>Error</div>;

  const data = useStockChangeListQuery({
    variables: { orderBy: '', skip: (currentPage - 1) * pageSize, take: pageSize },
  });

  function itemRender(
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>,
  ) {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  }

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Stock</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/store/stock/changes')}
        title="Stock"
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
        extra={[
          <Button disabled={true} key="1" type="primary" onClick={() => setNewModelVisible(true)}>
            Add Stock Change
          </Button>,
        ]}
      ></PageHeader>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        {data.loading ? (
          <div>...loading</div>
        ) : (
          <>
            <div style={{ marginBottom: 20 }}>
              {data.data?.stockChangeList.items.map((x) => (
                <Row key={x.id} gutter={8} style={{ marginBottom: 10 }}>
                  <Col span="4">
                    <Link to={'./changes/' + x.id}>{dateTimeString(x.createdAt)}</Link>
                    <div>{x.type}</div>
                  </Col>

                  <Col span="10">
                    {x.inProductList.map((y) => (
                      <div key={y.id}>
                        <Link to={'/store/products/' + y.id}>{y.name}</Link>
                      </div>
                    ))}
                  </Col>
                  <Col span="10">
                    {x.outProductList.map((y) => (
                      <div key={y.id}>
                        <Link to={'/store/products/' + y.id}>{y.name}</Link>
                      </div>
                    ))}
                  </Col>
                </Row>
              ))}
            </div>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={data.data?.stockChangeList.totalCount}
              itemRender={itemRender}
              onChange={(x) => {
                setCurrentPage(x);
              }}
              onShowSizeChange={(current, size) => setPageSize(size)}
            />
          </>
        )}
      </div>
      <StoreStockChangeNewModal visible={newModalVisible} onClose={() => setNewModelVisible(false)} />
    </div>
  );
};

export default StockChangeListScreen;
