import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Category, OrderStatusType, useCategoryListQuery, useOrderStatusTypeListQuery } from '../Generated/graphql';
import { AuthContext } from './authContext';
import LoadingComponent from '../components/loadingComponent';

interface CommonDataContextType {
  categoryList: Array<Pick<Category, 'id' | 'name'>>;
  orderStatusTypeList: Array<OrderStatusType>;
  refreshCategoryList: () => void;
}

const CommonDataContext = React.createContext<CommonDataContextType>({} as CommonDataContextType);

const CommonDataProvider: React.FC = (props) => {
  const { signout } = useContext(AuthContext);
  const categoryListData = useCategoryListQuery();
  const orderStatusTypeListData = useOrderStatusTypeListQuery();

  if (categoryListData.loading) return <LoadingComponent>...loading common data</LoadingComponent>;
  if (categoryListData.error)
    return (
      <div>
        <div>...error: {categoryListData.error.message}</div>
        <div onClick={signout}>Log out</div>
      </div>
    );

  if (orderStatusTypeListData.loading) return <LoadingComponent>...loading common data</LoadingComponent>;
  if (orderStatusTypeListData.error)
    return (
      <div>
        <div>...error: {orderStatusTypeListData.error.message}</div>
        <div onClick={signout}>Log out</div>
      </div>
    );

  const categoryList = categoryListData.data?.categoryList ?? [];
  const orderStatusTypeList = orderStatusTypeListData.data?.orderStatusTypeList ?? [];

  return (
    <CommonDataContext.Provider
      value={{
        categoryList,
        orderStatusTypeList,
        refreshCategoryList: () => {
          categoryListData.refetch();
        },
      }}
    >
      {props.children}
    </CommonDataContext.Provider>
  );
};

export { CommonDataContext, CommonDataProvider };

CommonDataProvider.propTypes = {
  children: PropTypes.node,
};
