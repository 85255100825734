import { Breadcrumb, PageHeader } from 'antd';
import React from 'react';

const HomeScreen: React.FC = () => {
  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        title="Home"
        //subTitle="This is a subtitle"
        // extra={[
        //   <Button key="1" type="primary">
        //     Refresh
        //   </Button>,
        // ]}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      >
        {/* <Descriptions size="small" column={3}>
                    <Descriptions.Item label="Created">Lili Qu</Descriptions.Item>
                    <Descriptions.Item label="Association">
                        <a>421421</a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Creation Time">2017-01-10</Descriptions.Item>
                    <Descriptions.Item label="Effective Time">2017-10-10</Descriptions.Item>
                    <Descriptions.Item label="Remarks">
                        Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
                    </Descriptions.Item>
                </Descriptions> */}
      </PageHeader>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        Text here
      </div>
    </div>
  );
};

export default HomeScreen;
