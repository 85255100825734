import { PlaceParticipantSelectedId } from './keys';

export const getPlaceParticipantSelectedId = (): string | null => {
  return localStorage.getItem(PlaceParticipantSelectedId);
};

export const setPlaceParticipantSelectedId = (id: string | null): void => {
  if (id) localStorage.setItem(PlaceParticipantSelectedId, id);
  else localStorage.removeItem(PlaceParticipantSelectedId);
};
