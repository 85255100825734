import { formatDistance, parseISO, format as f, parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';

export function formatCurrency(number: number, def = '-'): string {
  if (!number) return def ?? '';

  return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 }).format(
    number,
  );
}
