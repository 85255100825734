import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { getPlaceParticipantSelectedId, setPlaceParticipantSelectedId } from '../storage/placeSelected';

interface PlaceContextType {
  placeParticipantId: string | null;
  setPlaceParticipantId(id: string | null): void;
}

const PlaceParticipantContext = React.createContext<PlaceContextType>({} as PlaceContextType);

const PlaceParticipantProvider: React.FC = (props) => {
  const [placeParticipantId, setPlaceParticipantIdInt] = useState(getPlaceParticipantSelectedId());

  const setPlaceParticipantId = useCallback((id: string | null) => {
    setPlaceParticipantSelectedId(id);
    setPlaceParticipantIdInt(id);
  }, []);

  return (
    <PlaceParticipantContext.Provider
      value={{
        placeParticipantId,
        setPlaceParticipantId,
      }}
    >
      {props.children}
    </PlaceParticipantContext.Provider>
  );
};

export { PlaceParticipantContext, PlaceParticipantProvider };

PlaceParticipantProvider.propTypes = {
  children: PropTypes.node,
};
