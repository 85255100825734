import React, { useState } from 'react';

import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import { ProductPicture } from '../Generated/graphql';

export interface Props {
  id: string;
  pictureList:
    | ({
        __typename?: 'ProductPicture' | undefined;
      } & Pick<ProductPicture, 'order' | 'fileName' | 'extension' | 'originalFileName' | 'url'>)[]
    | undefined;
}

const ProductItemPictures: React.FC<Props> = (props) => {
  const fileListx: UploadFile<any>[] =
    props.pictureList?.map((x) => ({
      uid: x.fileName,
      name: x.fileName,
      status: 'done',
      url: 'https://files.orderindo.com/images' + x.url,
    })) ?? [];

  const [fileList, setFileList] = useState<UploadFile<any>[]>(fileListx);

  const handleChange = ({ fileList }: any) => setFileList(fileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        action="https://localhost:44398/images"
        data={{ productId: props.id }}
        listType="picture-card"
        fileList={fileList}
        //onPreview={this.handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
    </>
  );
};

export default ProductItemPictures;

// ProductItemPictures.propTypes = {
//     productId: PropTypes.string
// };
