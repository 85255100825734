import { Badge, Breadcrumb, Col, PageHeader, Row } from 'antd';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ByAtToolTip from '../components/byAtToolTip';
import { useStoreContactQuery, useStoreOrderListByStorePurchaserIdQuery } from '../Generated/graphql';
import { formatCurrency } from '../utils/currency';
import { dateToString } from '../utils/date';

const PurchaserContactItemScreen: React.FC = () => {
  const { contactId } = useParams<{ contactId: string }>();
  const history = useHistory();

  const contact = useStoreContactQuery({
    variables: {
      id: contactId,
    },
  });

  const data = useStoreOrderListByStorePurchaserIdQuery({
    variables: {
      storePurchaserId: contactId,
      skip: 0,
      take: 20,
    },
  });

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/store/purchasers">Purchasers</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Purchaser</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/store/purchasers')}
        title={contact.loading ? '...loading' : contact.data?.storeContact?.name}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        {data.loading
          ? 'loading'
          : data.data?.orders.store.orderListByStorePurchaserId?.items.map((x) => (
              <Row key={x.id} gutter={8}>
                <Col xs={2}>
                  <Link to={`/purchaser/orders/${x.id}`}>{x.orderId}</Link>
                </Col>
                <Col xs={3}></Col>
                <Col xs={3} style={{ textAlign: 'right' }}>
                  {formatCurrency(x.totalNet)}
                </Col>
                <Col xs={3}>{x.type === 'invoice' ? 'Purchase' : 'Consignment'}</Col>
                <Col xs={4}>
                  <ByAtToolTip by={x.orderStatus.createdBy} at={x.orderStatus.createdAt}>
                    {x.orderStatus.name}
                  </ByAtToolTip>
                </Col>
                <Col xs={4}>
                  <ByAtToolTip by={x.paymentStatus.createdBy} at={x.paymentStatus.createdAt}>
                    <Badge
                      status={
                        x.paymentStatus.statusId === 'unpaid'
                          ? 'error'
                          : x.paymentStatus.statusId === 'unconfirmed'
                          ? 'warning'
                          : x.paymentStatus.statusId === 'paid'
                          ? 'success'
                          : 'default'
                      }
                      text={x.paymentStatus.name}
                    ></Badge>
                  </ByAtToolTip>
                </Col>

                <Col xs={4}>{dateToString(x.orderDate)}</Col>
              </Row>
            ))}
      </div>
    </div>
  );
};

export default PurchaserContactItemScreen;
