import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Place, PlaceParticipant, useMeQuery } from '../Generated/graphql';
import { AuthContext } from './authContext';
import { PlaceParticipantContext } from './placeParticipantContext';
import LoadingComponent from '../components/loadingComponent';

interface AccountContextType {
  placeParticipantList: Array<
    { __typename?: 'PlaceParticipant' } & Pick<PlaceParticipant, 'id'> & {
        place: { __typename?: 'Place' } & Pick<Place, 'id' | 'name'>;
      }
  >;
}

const AccountContext = React.createContext<AccountContextType>({} as AccountContextType);

const AccountProvider: React.FC = (props) => {
  const { signout } = useContext(AuthContext);
  const { placeParticipantId, setPlaceParticipantId } = useContext(PlaceParticipantContext);

  const me = useMeQuery();

  if (me.loading) return <LoadingComponent>...loading</LoadingComponent>;
  if (me.error)
    return (
      <div>
        <div>...error: {me.error.message}</div>
        <div onClick={signout}>Log out</div>
      </div>
    );

  const ppl = me.data?.me?.placeParticipantList ?? [];

  if (!placeParticipantId) {
    if (ppl.length === 0) return <div>...no places found</div>;

    if (ppl.length === 1) {
      setPlaceParticipantId(ppl[0].id);
    }

    return (
      <div>
        {ppl.map((x) => (
          <div
            key={x.id}
            onClick={() => {
              setPlaceParticipantId(x.id);
            }}
          >
            {x.place.name}
          </div>
        ))}
      </div>
    );
  } else {
    if (!ppl.find((x) => x.id === placeParticipantId)) {
      setPlaceParticipantId(null);
    }
  }

  return (
    <AccountContext.Provider
      value={{
        placeParticipantList: ppl,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountProvider };

AccountProvider.propTypes = {
  children: PropTypes.node,
};
