import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  Date: any;
  DateTime: any;
  DateTimeOffset: any;
  Decimal: any;
  Guid: any;
  Long: any;
  Milliseconds: any;
  SByte: any;
  Seconds: any;
  Short: any;
  UInt: any;
  ULong: any;
  UShort: any;
  Uri: any;
  _FieldSet: any;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  email: Scalars['String'];
  timezone: Scalars['String'];
  placeParticipantList: Array<PlaceParticipant>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  text: Scalars['String'];
  name: Scalars['String'];
  attnName: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  addressLine3: Scalars['String'];
  phoneCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  directions: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type CartInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cartItemList: Array<CartItemInput>;
  placeId?: Maybe<Scalars['String']>;
};

export type CartItemInput = {
  id?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  added?: Maybe<Array<Maybe<Scalars['String']>>>;
  removed?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  description: Scalars['String'];
};

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['ID'];
  name: Scalars['String'];
  minimumOrderTotal?: Maybe<Scalars['Decimal']>;
  minimumQuantityTotal?: Maybe<Scalars['Decimal']>;
  percentageDiscount?: Maybe<Scalars['Decimal']>;
};

export type CutoffItem = {
  __typename?: 'CutoffItem';
  id: Scalars['ID'];
  cutoffAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  limitList?: Maybe<Array<Scalars['String']>>;
  allowedOrderActionTypeIdList: Array<Scalars['String']>;
};

export type DeliveryOption = {
  __typename?: 'DeliveryOption';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<DeliveryType>;
};

export type DeliverySchedule = {
  __typename?: 'DeliverySchedule';
  id: Scalars['ID'];
  itemList: Array<DeliveryScheduleItem>;
};

export type DeliveryScheduleItem = {
  __typename?: 'DeliveryScheduleItem';
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  deliveryAt: Scalars['DateTime'];
  cutoffList: Array<CutoffItem>;
};

export type DeliveryType = {
  __typename?: 'DeliveryType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  deliverySchedule: DeliverySchedule;
};

export type Discount = {
  __typename?: 'Discount';
  id: Scalars['ID'];
  name: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo?: Maybe<Scalars['DateTime']>;
  criteria: DiscountCriteria;
  fixedPrice?: Maybe<DiscountFixedPrice>;
  discountPercentage?: Maybe<Scalars['Decimal']>;
};

export type DiscountCriteria = {
  __typename?: 'DiscountCriteria';
  and: Array<DiscountCriteria>;
  or: Array<DiscountCriteria>;
  productId?: Maybe<Scalars['String']>;
  discountTagId?: Maybe<Scalars['String']>;
};

export type DiscountCriteriaInput = {
  and: Array<DiscountCriteriaInput>;
  or: Array<DiscountCriteriaInput>;
  productId?: Maybe<Scalars['String']>;
  discountTagId?: Maybe<Scalars['String']>;
};

export type DiscountFixedPrice = {
  __typename?: 'DiscountFixedPrice';
  fixedAt?: Maybe<Scalars['String']>;
  servicePercentage: Scalars['Decimal'];
  taxPercentage: Scalars['Decimal'];
  fixedPrice: Scalars['Decimal'];
};

export type DiscountInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo?: Maybe<Scalars['DateTime']>;
  criteria: DiscountCriteriaInput;
  discountPercentage?: Maybe<Scalars['Decimal']>;
};

export type DiscountPageInfo = {
  __typename?: 'DiscountPageInfo';
  totalCount: Scalars['Int'];
  list: Array<Discount>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  fileExtension: Scalars['String'];
  fileTypeId: Scalars['String'];
  url: Scalars['String'];
};

export type Menu = {
  __typename?: 'Menu';
  id: Scalars['ID'];
  groupList: MenuGroupList;
};

export type MenuGroup = {
  __typename?: 'MenuGroup';
  name: Scalars['String'];
  itemList: Array<MenuGroupItem>;
};

export type MenuGroupItem = {
  __typename?: 'MenuGroupItem';
  productAction?: Maybe<MenuProductAction>;
  groupList?: Maybe<MenuGroupList>;
};

export type MenuGroupList = {
  __typename?: 'MenuGroupList';
  name: Scalars['String'];
  list: Array<MenuGroup>;
};

export type MenuProductAction = {
  __typename?: 'MenuProductAction';
  name: Scalars['String'];
  productId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createContact: StorePurchaser;
  updateProductGeneral: Product;
  createPurchaseOrder: Order;
  updateOrderOrderDate: Order;
  updateOrderStatus: Order;
  createStockChange: Array<Scalars['String']>;
  upsertDiscount?: Maybe<Discount>;
  create?: Maybe<File>;
};

export type MutationCreateContactArgs = {
  name: Scalars['String'];
  placeTypeId: Scalars['String'];
};

export type MutationUpdateProductGeneralArgs = {
  data: UpdateProductGeneralInput;
};

export type MutationCreatePurchaseOrderArgs = {
  order: OrderInput;
};

export type MutationUpdateOrderOrderDateArgs = {
  orderId: Scalars['String'];
  orderDate: Scalars['DateTime'];
  type: Scalars['String'];
};

export type MutationUpdateOrderStatusArgs = {
  orderId: Scalars['String'];
  statusTypeId: Scalars['String'];
};

export type MutationCreateStockChangeArgs = {
  stockChange: StockChangeInput;
};

export type MutationUpsertDiscountArgs = {
  discount: DiscountInput;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  orderId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  orderDate: Scalars['DateTime'];
  type: Scalars['String'];
  totalNet: Scalars['Decimal'];
  orderStatus: OrderStatus;
  paymentStatus: OrderPaymentStatus;
  itemList: Array<OrderItem>;
  storePurchaserId?: Maybe<Scalars['String']>;
  storeContact?: Maybe<StoreContact>;
  purchaserContact?: Maybe<PurchaserContact>;
  fileList: Array<File>;
  url: Scalars['String'];
};

export type OrderG = {
  __typename?: 'OrderG';
  purchaser: PurchaserG;
  store: StoreG;
};

export type OrderInput = {
  contactId: Scalars['String'];
  originalFileName: Scalars['String'];
  fileData: Scalars['String'];
  documentDate: Scalars['DateTime'];
  type: Scalars['String'];
  itemList: Array<OrderItemInput>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  storeProductId?: Maybe<Scalars['String']>;
  purchaserProductId?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  totalNet: Scalars['Decimal'];
  storeProduct?: Maybe<Product>;
  purchaserProduct?: Maybe<Product>;
};

export type OrderItemInput = {
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  taxAmount: Scalars['Decimal'];
  totalAmount: Scalars['Decimal'];
};

export type OrderPageInfo = {
  __typename?: 'OrderPageInfo';
  totalCount: Scalars['Int'];
  items: Array<Order>;
};

export type OrderPaymentStatus = {
  __typename?: 'OrderPaymentStatus';
  id: Scalars['ID'];
  statusId: Scalars['String'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  type: OrderStatusType;
};

export type OrderStatusType = {
  __typename?: 'OrderStatusType';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type Outlet = {
  __typename?: 'Outlet';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type PaymentTerm = {
  __typename?: 'PaymentTerm';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  addDays: Scalars['Int'];
};

export type Place = {
  __typename?: 'Place';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  themeColor: Scalars['String'];
  outletList: Array<Outlet>;
  tableList: Array<Table>;
  menu?: Maybe<Menu>;
  productList: ProductPageInfo;
  couponList: Array<Coupon>;
  discountList: Array<Discount>;
};

export type PlaceProductListArgs = {
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PlaceParticipant = {
  __typename?: 'PlaceParticipant';
  id: Scalars['ID'];
  name: Scalars['String'];
  place: Place;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  productId: Scalars['String'];
  productUrl: Scalars['String'];
  category?: Maybe<Category>;
  name: Scalars['String'];
  description: Scalars['String'];
  volume: Scalars['Decimal'];
  weight: Scalars['Int'];
  isOnline: Scalars['Boolean'];
  stockAvailable: Scalars['Boolean'];
  limitedPlaceTypeIdList: Array<Scalars['String']>;
  discountTagIdList: Array<Scalars['String']>;
  pictureList: Array<ProductPicture>;
  primaryPicture?: Maybe<ProductPicture>;
  stockItemList: StockItemPage;
  summary: StockSummary;
  discountList: Array<Discount>;
};

export type ProductDiscountListArgs = {
  active?: Maybe<Scalars['Boolean']>;
};

export type ProductPageInfo = {
  __typename?: 'ProductPageInfo';
  totalItemCount: Scalars['Int'];
  products: Array<Product>;
};

export type ProductPicture = {
  __typename?: 'ProductPicture';
  order: Scalars['Int'];
  fileName: Scalars['String'];
  extension: Scalars['String'];
  originalFileName: Scalars['String'];
  url: Scalars['String'];
};

export type PurchaserContact = {
  __typename?: 'PurchaserContact';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  place?: Maybe<Place>;
};

export type PurchaserContactPageInfo = {
  __typename?: 'PurchaserContactPageInfo';
  totalCount: Scalars['Int'];
  contactList: Array<PurchaserContact>;
};

export type PurchaserG = {
  __typename?: 'PurchaserG';
  orderList?: Maybe<OrderPageInfo>;
  orderListByStorePurchaserId?: Maybe<OrderPageInfo>;
};

export type PurchaserGOrderListArgs = {
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PurchaserGOrderListByStorePurchaserIdArgs = {
  storePurchaserId: Scalars['ID'];
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<Account>;
  placeParticipant?: Maybe<PlaceParticipant>;
  storePurchaser: StorePurchaser;
  purchaserContactList: PurchaserContactPageInfo;
  purchaser?: Maybe<PurchaserContact>;
  storeContactList: StoreContactPageInfo;
  storeContact?: Maybe<StoreContact>;
  pame?: Maybe<Scalars['String']>;
  productList: ProductPageInfo;
  product?: Maybe<Product>;
  categoryList: Array<Category>;
  orderById: Order;
  orders: OrderG;
  orderStatusTypeList: Array<OrderStatusType>;
  stockItem?: Maybe<StockItem>;
  stockItemList: StockItemPage;
  stockChange?: Maybe<StockChange>;
  stockChangeList: StockChangePage;
  discountList: DiscountPageInfo;
  discount?: Maybe<Discount>;
  file?: Maybe<File>;
};

export type QueryPlaceParticipantArgs = {
  id: Scalars['String'];
};

export type QueryStorePurchaserArgs = {
  id: Scalars['String'];
};

export type QueryPurchaserContactListArgs = {
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryPurchaserArgs = {
  id: Scalars['String'];
};

export type QueryStoreContactListArgs = {
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryStoreContactArgs = {
  id: Scalars['String'];
};

export type QueryProductListArgs = {
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryProductArgs = {
  id: Scalars['String'];
};

export type QueryOrderByIdArgs = {
  id?: Maybe<Scalars['String']>;
};

export type QueryStockItemArgs = {
  id: Scalars['ID'];
};

export type QueryStockItemListArgs = {
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryStockChangeArgs = {
  id: Scalars['ID'];
};

export type QueryStockChangeListArgs = {
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryDiscountListArgs = {
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryDiscountArgs = {
  id: Scalars['String'];
};

export type QueryFileArgs = {
  id: Scalars['String'];
};

export type StockChange = {
  __typename?: 'StockChange';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  changeDate: Scalars['DateTime'];
  type: Scalars['String'];
  inProductIdList: Array<Scalars['String']>;
  inStockItemList: Array<StockItem>;
  outProductIdList: Array<Scalars['String']>;
  outStockItemList: Array<StockItem>;
  inProductList: Array<Product>;
  outProductList: Array<Product>;
};

export type StockChangeInput = {
  type: Scalars['String'];
  changeDate: Scalars['DateTime'];
  inProductIdList: Array<Scalars['String']>;
  outProductIdList: Array<Scalars['String']>;
  quantity: Scalars['Int'];
  description: Scalars['String'];
};

export type StockChangePage = {
  __typename?: 'StockChangePage';
  totalCount: Scalars['Int'];
  items: Array<StockChange>;
};

export type StockItem = {
  __typename?: 'StockItem';
  id: Scalars['ID'];
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  productId: Scalars['String'];
  inPriceNet?: Maybe<Scalars['Decimal']>;
  inDateTime?: Maybe<Scalars['DateTime']>;
  outPriceNet?: Maybe<Scalars['Decimal']>;
  outDateTime?: Maybe<Scalars['DateTime']>;
  inOrder?: Maybe<Order>;
  outOrder?: Maybe<Order>;
  inStockChange?: Maybe<StockChange>;
  outStockChange?: Maybe<StockChange>;
  product: Product;
};

export type StockItemPage = {
  __typename?: 'StockItemPage';
  totalCount: Scalars['Int'];
  items: Array<StockItem>;
};

export type StockSummary = {
  __typename?: 'StockSummary';
  in: Scalars['Long'];
  out: Scalars['Long'];
};

export type StoreContact = {
  __typename?: 'StoreContact';
  id: Scalars['ID'];
  name: Scalars['String'];
  place?: Maybe<Place>;
};

export type StoreContactPageInfo = {
  __typename?: 'StoreContactPageInfo';
  totalCount: Scalars['Int'];
  contactList: Array<StoreContact>;
};

export type StoreG = {
  __typename?: 'StoreG';
  orderList?: Maybe<OrderPageInfo>;
  orderListByStorePurchaserId?: Maybe<OrderPageInfo>;
};

export type StoreGOrderListArgs = {
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type StoreGOrderListByStorePurchaserIdArgs = {
  storePurchaserId: Scalars['ID'];
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type StorePurchaser = {
  __typename?: 'StorePurchaser';
  id: Scalars['ID'];
  name: Scalars['String'];
  currency: Scalars['String'];
  store?: Maybe<Place>;
  purchaser?: Maybe<Place>;
  address: Address;
  purchaserTypeTagIdList: Array<Scalars['String']>;
  deliveryOptionList?: Maybe<Array<DeliveryOption>>;
  paymentTerm?: Maybe<PaymentTerm>;
  categories: Array<Category>;
  products?: Maybe<ProductPageInfo>;
};

export type StorePurchaserCategoriesArgs = {
  categoryUrlList?: Maybe<Array<Scalars['String']>>;
  orderBy?: Maybe<Scalars['String']>;
};

export type StorePurchaserProductsArgs = {
  productIdList?: Maybe<Array<Scalars['String']>>;
  categoryUrlList?: Maybe<Array<Scalars['String']>>;
  productUrlList?: Maybe<Array<Scalars['String']>>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Table = {
  __typename?: 'Table';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateProductGeneralInput = {
  id: Scalars['ID'];
  productId: Scalars['String'];
  categoryId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  volume: Scalars['Decimal'];
  weight: Scalars['Int'];
  isOnline: Scalars['Boolean'];
  stockAvailable: Scalars['Boolean'];
  limitedPlaceTypeIdList: Array<Scalars['String']>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'Account' } & Pick<Account, 'id'> & {
        placeParticipantList: Array<
          { __typename?: 'PlaceParticipant' } & Pick<PlaceParticipant, 'id'> & {
              place: { __typename?: 'Place' } & Pick<Place, 'id' | 'name'>;
            }
        >;
      }
  >;
};

export type CategoryListQueryVariables = Exact<{ [key: string]: never }>;

export type CategoryListQuery = { __typename?: 'Query' } & {
  categoryList: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
};

export type CreateContactMutationVariables = Exact<{
  name: Scalars['String'];
  placeTypeId: Scalars['String'];
}>;

export type CreateContactMutation = { __typename?: 'Mutation' } & {
  createContact: { __typename?: 'StorePurchaser' } & Pick<StorePurchaser, 'id'>;
};

export type CreatePurchaseOrderMutationVariables = Exact<{
  order: OrderInput;
}>;

export type CreatePurchaseOrderMutation = { __typename?: 'Mutation' } & {
  createPurchaseOrder: { __typename?: 'Order' } & Pick<Order, 'id'>;
};

export type DiscountQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type DiscountQuery = { __typename?: 'Query' } & {
  discount?: Maybe<{ __typename?: 'Discount' } & Pick<Discount, 'id' | 'name'>>;
};

export type DiscountListQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;

export type DiscountListQuery = { __typename?: 'Query' } & {
  discountList: { __typename?: 'DiscountPageInfo' } & Pick<DiscountPageInfo, 'totalCount'> & {
      list: Array<{ __typename?: 'Discount' } & Pick<Discount, 'id' | 'name'>>;
    };
};

export type UpsertDiscountMutationVariables = Exact<{
  discount: DiscountInput;
}>;

export type UpsertDiscountMutation = { __typename?: 'Mutation' } & {
  upsertDiscount?: Maybe<{ __typename?: 'Discount' } & Pick<Discount, 'id'>>;
};

export type OrderQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type OrderQuery = { __typename?: 'Query' } & {
  orderById: { __typename?: 'Order' } & Pick<
    Order,
    'id' | 'orderId' | 'orderDate' | 'type' | 'createdAt' | 'createdBy' | 'totalNet'
  > & {
      storeContact?: Maybe<
        { __typename?: 'StoreContact' } & Pick<StoreContact, 'id' | 'name'> & {
            place?: Maybe<{ __typename?: 'Place' } & Pick<Place, 'id'>>;
          }
      >;
      purchaserContact?: Maybe<
        { __typename?: 'PurchaserContact' } & Pick<PurchaserContact, 'id' | 'name'> & {
            place?: Maybe<{ __typename?: 'Place' } & Pick<Place, 'id'>>;
          }
      >;
      orderStatus: { __typename?: 'OrderStatus' } & Pick<OrderStatus, 'id' | 'createdBy' | 'createdAt' | 'name'> & {
          type: { __typename?: 'OrderStatusType' } & Pick<OrderStatusType, 'id'>;
        };
      paymentStatus: { __typename?: 'OrderPaymentStatus' } & Pick<
        OrderPaymentStatus,
        'id' | 'statusId' | 'createdBy' | 'createdAt' | 'name'
      >;
      itemList: Array<
        { __typename?: 'OrderItem' } & Pick<OrderItem, 'id' | 'quantity' | 'totalNet'> & {
            storeProduct?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'id' | 'name'>>;
            purchaserProduct?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'id' | 'name'>>;
          }
      >;
      fileList: Array<{ __typename?: 'File' } & Pick<File, 'id' | 'fileExtension' | 'fileTypeId' | 'url'>>;
    };
};

export type OrderStatusTypeListQueryVariables = Exact<{ [key: string]: never }>;

export type OrderStatusTypeListQuery = { __typename?: 'Query' } & {
  orderStatusTypeList: Array<{ __typename?: 'OrderStatusType' } & Pick<OrderStatusType, 'id' | 'title'>>;
};

export type PlaceParticipantQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PlaceParticipantQuery = { __typename?: 'Query' } & {
  placeParticipant?: Maybe<
    { __typename?: 'PlaceParticipant' } & Pick<PlaceParticipant, 'id' | 'name'> & {
        place: { __typename?: 'Place' } & Pick<Place, 'id'>;
      }
  >;
};

export type ProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ProductQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'Product' } & Pick<
      Product,
      | 'id'
      | 'productId'
      | 'productUrl'
      | 'name'
      | 'description'
      | 'volume'
      | 'weight'
      | 'isOnline'
      | 'stockAvailable'
      | 'limitedPlaceTypeIdList'
      | 'discountTagIdList'
    > & {
        category?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
        pictureList: Array<
          { __typename?: 'ProductPicture' } & Pick<
            ProductPicture,
            'order' | 'fileName' | 'extension' | 'originalFileName' | 'url'
          >
        >;
        discountList: Array<
          { __typename?: 'Discount' } & Pick<Discount, 'id' | 'activeFrom' | 'activeTo' | 'discountPercentage'>
        >;
        stockItemList: { __typename?: 'StockItemPage' } & Pick<StockItemPage, 'totalCount'> & {
            items: Array<
              { __typename?: 'StockItem' } & Pick<
                StockItem,
                'id' | 'type' | 'createdAt' | 'inPriceNet' | 'inDateTime' | 'outPriceNet' | 'outDateTime'
              > & {
                  inOrder?: Maybe<{ __typename?: 'Order' } & Pick<Order, 'id' | 'orderId' | 'createdAt' | 'orderDate'>>;
                  outOrder?: Maybe<{ __typename?: 'Order' } & Pick<Order, 'id' | 'orderId'>>;
                  inStockChange?: Maybe<{ __typename?: 'StockChange' } & Pick<StockChange, 'id' | 'type'>>;
                  outStockChange?: Maybe<{ __typename?: 'StockChange' } & Pick<StockChange, 'id' | 'type'>>;
                }
            >;
          };
      }
  >;
};

export type ProductListQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;

export type ProductListQuery = { __typename?: 'Query' } & {
  productList: { __typename?: 'ProductPageInfo' } & Pick<ProductPageInfo, 'totalItemCount'> & {
      products: Array<
        { __typename?: 'Product' } & Pick<Product, 'id' | 'productId' | 'productUrl' | 'name'> & {
            category?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
            primaryPicture?: Maybe<{ __typename?: 'ProductPicture' } & Pick<ProductPicture, 'url'>>;
            summary: { __typename?: 'StockSummary' } & Pick<StockSummary, 'in' | 'out'>;
          }
      >;
    };
};

export type PurchaserContactListQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;

export type PurchaserContactListQuery = { __typename?: 'Query' } & {
  purchaserContactList: { __typename?: 'PurchaserContactPageInfo' } & Pick<PurchaserContactPageInfo, 'totalCount'> & {
      contactList: Array<
        { __typename?: 'PurchaserContact' } & Pick<PurchaserContact, 'id' | 'name' | 'type'> & {
            place?: Maybe<{ __typename?: 'Place' } & Pick<Place, 'id'>>;
          }
      >;
    };
};

export type PurchaserOrderListQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;

export type PurchaserOrderListQuery = { __typename?: 'Query' } & {
  orders: { __typename?: 'OrderG' } & {
    purchaser: { __typename?: 'PurchaserG' } & {
      orderList?: Maybe<
        { __typename?: 'OrderPageInfo' } & Pick<OrderPageInfo, 'totalCount'> & {
            items: Array<
              { __typename?: 'Order' } & Pick<
                Order,
                'id' | 'orderId' | 'createdAt' | 'createdBy' | 'orderDate' | 'type' | 'totalNet'
              > & {
                  storeContact?: Maybe<
                    { __typename?: 'StoreContact' } & Pick<StoreContact, 'id' | 'name'> & {
                        place?: Maybe<{ __typename?: 'Place' } & Pick<Place, 'id'>>;
                      }
                  >;
                  orderStatus: { __typename?: 'OrderStatus' } & Pick<
                    OrderStatus,
                    'id' | 'createdBy' | 'createdAt' | 'name'
                  >;
                  paymentStatus: { __typename?: 'OrderPaymentStatus' } & Pick<
                    OrderPaymentStatus,
                    'id' | 'statusId' | 'createdBy' | 'createdAt' | 'name'
                  >;
                }
            >;
          }
      >;
    };
  };
};

export type PurchaserOrderListByStorePurchaserIdQueryVariables = Exact<{
  storePurchaserId: Scalars['ID'];
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;

export type PurchaserOrderListByStorePurchaserIdQuery = { __typename?: 'Query' } & {
  orders: { __typename?: 'OrderG' } & {
    purchaser: { __typename?: 'PurchaserG' } & {
      orderListByStorePurchaserId?: Maybe<
        { __typename?: 'OrderPageInfo' } & Pick<OrderPageInfo, 'totalCount'> & {
            items: Array<
              { __typename?: 'Order' } & Pick<
                Order,
                'id' | 'orderId' | 'createdAt' | 'createdBy' | 'orderDate' | 'type' | 'totalNet'
              > & {
                  storeContact?: Maybe<
                    { __typename?: 'StoreContact' } & Pick<StoreContact, 'id' | 'name'> & {
                        place?: Maybe<{ __typename?: 'Place' } & Pick<Place, 'id'>>;
                      }
                  >;
                  orderStatus: { __typename?: 'OrderStatus' } & Pick<
                    OrderStatus,
                    'id' | 'createdBy' | 'createdAt' | 'name'
                  >;
                  paymentStatus: { __typename?: 'OrderPaymentStatus' } & Pick<
                    OrderPaymentStatus,
                    'id' | 'statusId' | 'createdBy' | 'createdAt' | 'name'
                  >;
                }
            >;
          }
      >;
    };
  };
};

export type StockChangeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StockChangeQuery = { __typename?: 'Query' } & {
  stockChange?: Maybe<
    { __typename?: 'StockChange' } & Pick<
      StockChange,
      'id' | 'type' | 'createdAt' | 'inProductIdList' | 'outProductIdList'
    > & {
        inStockItemList: Array<
          { __typename?: 'StockItem' } & Pick<StockItem, 'id' | 'type'> & {
              product: { __typename?: 'Product' } & Pick<Product, 'id' | 'name'>;
            }
        >;
        outStockItemList: Array<
          { __typename?: 'StockItem' } & Pick<StockItem, 'id' | 'type'> & {
              product: { __typename?: 'Product' } & Pick<Product, 'id' | 'name'>;
            }
        >;
        inProductList: Array<{ __typename?: 'Product' } & Pick<Product, 'id' | 'productId' | 'name'>>;
        outProductList: Array<{ __typename?: 'Product' } & Pick<Product, 'id' | 'productId' | 'name'>>;
      }
  >;
};

export type CreateStockChangeMutationVariables = Exact<{
  stockChange: StockChangeInput;
}>;

export type CreateStockChangeMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createStockChange'>;

export type StockChangeListQueryVariables = Exact<{
  orderBy: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;

export type StockChangeListQuery = { __typename?: 'Query' } & {
  stockChangeList: { __typename?: 'StockChangePage' } & Pick<StockChangePage, 'totalCount'> & {
      items: Array<
        { __typename?: 'StockChange' } & Pick<
          StockChange,
          'id' | 'type' | 'createdAt' | 'inProductIdList' | 'outProductIdList'
        > & {
            inProductList: Array<{ __typename?: 'Product' } & Pick<Product, 'id' | 'productId' | 'name'>>;
            outProductList: Array<{ __typename?: 'Product' } & Pick<Product, 'id' | 'productId' | 'name'>>;
          }
      >;
    };
};

export type StockItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StockItemQuery = { __typename?: 'Query' } & {
  stockItem?: Maybe<
    { __typename?: 'StockItem' } & Pick<
      StockItem,
      'id' | 'type' | 'createdAt' | 'inPriceNet' | 'inDateTime' | 'outPriceNet' | 'outDateTime'
    > & {
        product: { __typename?: 'Product' } & Pick<Product, 'id' | 'name'>;
        inOrder?: Maybe<{ __typename?: 'Order' } & Pick<Order, 'id' | 'orderId'>>;
        outOrder?: Maybe<{ __typename?: 'Order' } & Pick<Order, 'id' | 'orderId'>>;
        inStockChange?: Maybe<
          { __typename?: 'StockChange' } & Pick<StockChange, 'id' | 'type'> & {
              inStockItemList: Array<{ __typename?: 'StockItem' } & Pick<StockItem, 'id'>>;
            }
        >;
        outStockChange?: Maybe<
          { __typename?: 'StockChange' } & Pick<StockChange, 'id' | 'type'> & {
              outStockItemList: Array<{ __typename?: 'StockItem' } & Pick<StockItem, 'id'>>;
            }
        >;
      }
  >;
};

export type StockItemListQueryVariables = Exact<{
  orderBy: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;

export type StockItemListQuery = { __typename?: 'Query' } & {
  stockItemList: { __typename?: 'StockItemPage' } & Pick<StockItemPage, 'totalCount'> & {
      items: Array<
        { __typename?: 'StockItem' } & Pick<
          StockItem,
          'id' | 'type' | 'createdAt' | 'inPriceNet' | 'inDateTime' | 'outPriceNet' | 'outDateTime'
        > & {
            product: { __typename?: 'Product' } & Pick<Product, 'id' | 'name'>;
            inOrder?: Maybe<{ __typename?: 'Order' } & Pick<Order, 'id' | 'orderId'>>;
            outOrder?: Maybe<{ __typename?: 'Order' } & Pick<Order, 'id' | 'orderId'>>;
            inStockChange?: Maybe<{ __typename?: 'StockChange' } & Pick<StockChange, 'id' | 'type'>>;
            outStockChange?: Maybe<{ __typename?: 'StockChange' } & Pick<StockChange, 'id' | 'type'>>;
          }
      >;
    };
};

export type StoreContactQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type StoreContactQuery = { __typename?: 'Query' } & {
  storeContact?: Maybe<
    { __typename?: 'StoreContact' } & Pick<StoreContact, 'id' | 'name'> & {
        place?: Maybe<{ __typename?: 'Place' } & Pick<Place, 'id'>>;
      }
  >;
};

export type StoreContactListQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;

export type StoreContactListQuery = { __typename?: 'Query' } & {
  storeContactList: { __typename?: 'StoreContactPageInfo' } & Pick<StoreContactPageInfo, 'totalCount'> & {
      contactList: Array<
        { __typename?: 'StoreContact' } & Pick<StoreContact, 'id' | 'name'> & {
            place?: Maybe<{ __typename?: 'Place' } & Pick<Place, 'id'>>;
          }
      >;
    };
};

export type StoreOrderListQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;

export type StoreOrderListQuery = { __typename?: 'Query' } & {
  orders: { __typename?: 'OrderG' } & {
    store: { __typename?: 'StoreG' } & {
      orderList?: Maybe<
        { __typename?: 'OrderPageInfo' } & Pick<OrderPageInfo, 'totalCount'> & {
            items: Array<
              { __typename?: 'Order' } & Pick<
                Order,
                'id' | 'orderId' | 'createdAt' | 'createdBy' | 'orderDate' | 'type' | 'totalNet'
              > & {
                  purchaserContact?: Maybe<
                    { __typename?: 'PurchaserContact' } & Pick<PurchaserContact, 'id' | 'name'> & {
                        place?: Maybe<{ __typename?: 'Place' } & Pick<Place, 'id'>>;
                      }
                  >;
                  orderStatus: { __typename?: 'OrderStatus' } & Pick<
                    OrderStatus,
                    'id' | 'createdBy' | 'createdAt' | 'name'
                  >;
                  paymentStatus: { __typename?: 'OrderPaymentStatus' } & Pick<
                    OrderPaymentStatus,
                    'id' | 'statusId' | 'createdBy' | 'createdAt' | 'name'
                  >;
                }
            >;
          }
      >;
    };
  };
};

export type StoreOrderListByStorePurchaserIdQueryVariables = Exact<{
  storePurchaserId: Scalars['ID'];
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;

export type StoreOrderListByStorePurchaserIdQuery = { __typename?: 'Query' } & {
  orders: { __typename?: 'OrderG' } & {
    store: { __typename?: 'StoreG' } & {
      orderListByStorePurchaserId?: Maybe<
        { __typename?: 'OrderPageInfo' } & Pick<OrderPageInfo, 'totalCount'> & {
            items: Array<
              { __typename?: 'Order' } & Pick<
                Order,
                'id' | 'orderId' | 'createdAt' | 'createdBy' | 'orderDate' | 'type' | 'totalNet'
              > & {
                  purchaserContact?: Maybe<
                    { __typename?: 'PurchaserContact' } & Pick<PurchaserContact, 'id' | 'name'> & {
                        place?: Maybe<{ __typename?: 'Place' } & Pick<Place, 'id'>>;
                      }
                  >;
                  orderStatus: { __typename?: 'OrderStatus' } & Pick<
                    OrderStatus,
                    'id' | 'createdBy' | 'createdAt' | 'name'
                  >;
                  paymentStatus: { __typename?: 'OrderPaymentStatus' } & Pick<
                    OrderPaymentStatus,
                    'id' | 'statusId' | 'createdBy' | 'createdAt' | 'name'
                  >;
                }
            >;
          }
      >;
    };
  };
};

export type UpdateOrderOrderDateMutationVariables = Exact<{
  orderId: Scalars['String'];
  orderDate: Scalars['DateTime'];
  type: Scalars['String'];
}>;

export type UpdateOrderOrderDateMutation = { __typename?: 'Mutation' } & {
  updateOrderOrderDate: { __typename?: 'Order' } & Pick<Order, 'id' | 'orderDate' | 'type'>;
};

export type UpdateOrderStatusMutationVariables = Exact<{
  orderId: Scalars['String'];
  statusTypeId: Scalars['String'];
}>;

export type UpdateOrderStatusMutation = { __typename?: 'Mutation' } & {
  updateOrderStatus: { __typename?: 'Order' } & Pick<Order, 'id'> & {
      orderStatus: { __typename?: 'OrderStatus' } & {
        type: { __typename?: 'OrderStatusType' } & Pick<OrderStatusType, 'id'>;
      };
    };
};

export type UpdateProductGeneralMutationVariables = Exact<{
  data: UpdateProductGeneralInput;
}>;

export type UpdateProductGeneralMutation = { __typename?: 'Mutation' } & {
  updateProductGeneral: { __typename?: 'Product' } & Pick<
    Product,
    | 'id'
    | 'productId'
    | 'productUrl'
    | 'name'
    | 'description'
    | 'volume'
    | 'weight'
    | 'isOnline'
    | 'stockAvailable'
    | 'limitedPlaceTypeIdList'
    | 'discountTagIdList'
  > & {
      category?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
      pictureList: Array<
        { __typename?: 'ProductPicture' } & Pick<
          ProductPicture,
          'order' | 'fileName' | 'extension' | 'originalFileName'
        >
      >;
    };
};

export const MeDocument = gql`
  query me {
    me {
      id
      placeParticipantList {
        id
        place {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CategoryListDocument = gql`
  query categoryList {
    categoryList {
      id
      name
    }
  }
`;

/**
 * __useCategoryListQuery__
 *
 * To run a query within a React component, call `useCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoryListQuery(
  baseOptions?: Apollo.QueryHookOptions<CategoryListQuery, CategoryListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, options);
}
export function useCategoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CategoryListQuery, CategoryListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, options);
}
export type CategoryListQueryHookResult = ReturnType<typeof useCategoryListQuery>;
export type CategoryListLazyQueryHookResult = ReturnType<typeof useCategoryListLazyQuery>;
export type CategoryListQueryResult = Apollo.QueryResult<CategoryListQuery, CategoryListQueryVariables>;
export const CreateContactDocument = gql`
  mutation createContact($name: String!, $placeTypeId: String!) {
    createContact(name: $name, placeTypeId: $placeTypeId) {
      id
    }
  }
`;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      name: // value for 'name'
 *      placeTypeId: // value for 'placeTypeId'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const CreatePurchaseOrderDocument = gql`
  mutation createPurchaseOrder($order: OrderInput!) {
    createPurchaseOrder(order: $order) {
      id
    }
  }
`;

/**
 * __useCreatePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseOrderMutation, { data, loading, error }] = useCreatePurchaseOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreatePurchaseOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables>(
    CreatePurchaseOrderDocument,
    options,
  );
}
export type CreatePurchaseOrderMutationHookResult = ReturnType<typeof useCreatePurchaseOrderMutation>;
export type CreatePurchaseOrderMutationResult = Apollo.MutationResult<CreatePurchaseOrderMutation>;
export type CreatePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<
  CreatePurchaseOrderMutation,
  CreatePurchaseOrderMutationVariables
>;
export const DiscountDocument = gql`
  query discount($id: String!) {
    discount(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useDiscountQuery__
 *
 * To run a query within a React component, call `useDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountQuery(baseOptions: Apollo.QueryHookOptions<DiscountQuery, DiscountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DiscountQuery, DiscountQueryVariables>(DiscountDocument, options);
}
export function useDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountQuery, DiscountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DiscountQuery, DiscountQueryVariables>(DiscountDocument, options);
}
export type DiscountQueryHookResult = ReturnType<typeof useDiscountQuery>;
export type DiscountLazyQueryHookResult = ReturnType<typeof useDiscountLazyQuery>;
export type DiscountQueryResult = Apollo.QueryResult<DiscountQuery, DiscountQueryVariables>;
export const DiscountListDocument = gql`
  query discountList($query: String, $orderBy: String, $skip: Int, $take: Int) {
    discountList(query: $query, orderBy: $orderBy, skip: $skip, take: $take) {
      totalCount
      list {
        id
        name
      }
    }
  }
`;

/**
 * __useDiscountListQuery__
 *
 * To run a query within a React component, call `useDiscountListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountListQuery({
 *   variables: {
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useDiscountListQuery(
  baseOptions?: Apollo.QueryHookOptions<DiscountListQuery, DiscountListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DiscountListQuery, DiscountListQueryVariables>(DiscountListDocument, options);
}
export function useDiscountListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DiscountListQuery, DiscountListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DiscountListQuery, DiscountListQueryVariables>(DiscountListDocument, options);
}
export type DiscountListQueryHookResult = ReturnType<typeof useDiscountListQuery>;
export type DiscountListLazyQueryHookResult = ReturnType<typeof useDiscountListLazyQuery>;
export type DiscountListQueryResult = Apollo.QueryResult<DiscountListQuery, DiscountListQueryVariables>;
export const UpsertDiscountDocument = gql`
  mutation upsertDiscount($discount: DiscountInput!) {
    upsertDiscount(discount: $discount) {
      id
    }
  }
`;

/**
 * __useUpsertDiscountMutation__
 *
 * To run a mutation, you first call `useUpsertDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDiscountMutation, { data, loading, error }] = useUpsertDiscountMutation({
 *   variables: {
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useUpsertDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertDiscountMutation, UpsertDiscountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertDiscountMutation, UpsertDiscountMutationVariables>(UpsertDiscountDocument, options);
}
export type UpsertDiscountMutationHookResult = ReturnType<typeof useUpsertDiscountMutation>;
export type UpsertDiscountMutationResult = Apollo.MutationResult<UpsertDiscountMutation>;
export type UpsertDiscountMutationOptions = Apollo.BaseMutationOptions<
  UpsertDiscountMutation,
  UpsertDiscountMutationVariables
>;
export const OrderDocument = gql`
  query order($id: String!) {
    orderById(id: $id) {
      id
      orderId
      orderDate
      type
      createdAt
      createdBy
      totalNet
      storeContact {
        id
        name
        place {
          id
        }
      }
      purchaserContact {
        id
        name
        place {
          id
        }
      }
      orderStatus {
        id
        createdBy
        createdAt
        name
        type {
          id
        }
      }
      paymentStatus {
        id
        statusId
        createdBy
        createdAt
        name
      }
      itemList {
        id
        quantity
        totalNet
        storeProduct {
          id
          name
        }
        purchaserProduct {
          id
          name
        }
      }
      fileList {
        id
        fileExtension
        fileTypeId
        url
      }
    }
  }
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderStatusTypeListDocument = gql`
  query orderStatusTypeList {
    orderStatusTypeList {
      id
      title
    }
  }
`;

/**
 * __useOrderStatusTypeListQuery__
 *
 * To run a query within a React component, call `useOrderStatusTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatusTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatusTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderStatusTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderStatusTypeListQuery, OrderStatusTypeListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderStatusTypeListQuery, OrderStatusTypeListQueryVariables>(
    OrderStatusTypeListDocument,
    options,
  );
}
export function useOrderStatusTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderStatusTypeListQuery, OrderStatusTypeListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderStatusTypeListQuery, OrderStatusTypeListQueryVariables>(
    OrderStatusTypeListDocument,
    options,
  );
}
export type OrderStatusTypeListQueryHookResult = ReturnType<typeof useOrderStatusTypeListQuery>;
export type OrderStatusTypeListLazyQueryHookResult = ReturnType<typeof useOrderStatusTypeListLazyQuery>;
export type OrderStatusTypeListQueryResult = Apollo.QueryResult<
  OrderStatusTypeListQuery,
  OrderStatusTypeListQueryVariables
>;
export const PlaceParticipantDocument = gql`
  query placeParticipant($id: String!) {
    placeParticipant(id: $id) {
      id
      name
      place {
        id
      }
    }
  }
`;

/**
 * __usePlaceParticipantQuery__
 *
 * To run a query within a React component, call `usePlaceParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceParticipantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaceParticipantQuery(
  baseOptions: Apollo.QueryHookOptions<PlaceParticipantQuery, PlaceParticipantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlaceParticipantQuery, PlaceParticipantQueryVariables>(PlaceParticipantDocument, options);
}
export function usePlaceParticipantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlaceParticipantQuery, PlaceParticipantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlaceParticipantQuery, PlaceParticipantQueryVariables>(PlaceParticipantDocument, options);
}
export type PlaceParticipantQueryHookResult = ReturnType<typeof usePlaceParticipantQuery>;
export type PlaceParticipantLazyQueryHookResult = ReturnType<typeof usePlaceParticipantLazyQuery>;
export type PlaceParticipantQueryResult = Apollo.QueryResult<PlaceParticipantQuery, PlaceParticipantQueryVariables>;
export const ProductDocument = gql`
  query product($id: String!) {
    product(id: $id) {
      id
      productId
      productUrl
      category {
        id
        name
      }
      name
      description
      volume
      weight
      isOnline
      stockAvailable
      limitedPlaceTypeIdList
      discountTagIdList
      pictureList {
        order
        fileName
        extension
        originalFileName
        url
      }
      discountList {
        id
        activeFrom
        activeTo
        discountPercentage
      }
      stockItemList {
        items {
          id
          type
          createdAt
          inPriceNet
          inPriceNet
          inDateTime
          outPriceNet
          outDateTime
          inOrder {
            id
            orderId
            createdAt
            orderDate
          }
          outOrder {
            id
            orderId
          }
          inStockChange {
            id
            type
          }
          outStockChange {
            id
            type
          }
        }
        totalCount
      }
    }
  }
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductListDocument = gql`
  query productList($query: String, $orderBy: String, $skip: Int, $take: Int) {
    productList(query: $query, orderBy: $orderBy, skip: $skip, take: $take) {
      totalItemCount
      products {
        id
        productId
        productUrl
        category {
          id
          name
        }
        name
        primaryPicture {
          url
        }
        summary {
          in
          out
        }
      }
    }
  }
`;

/**
 * __useProductListQuery__
 *
 * To run a query within a React component, call `useProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductListQuery({
 *   variables: {
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useProductListQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductListQuery, ProductListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductListQuery, ProductListQueryVariables>(ProductListDocument, options);
}
export function useProductListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductListQuery, ProductListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductListQuery, ProductListQueryVariables>(ProductListDocument, options);
}
export type ProductListQueryHookResult = ReturnType<typeof useProductListQuery>;
export type ProductListLazyQueryHookResult = ReturnType<typeof useProductListLazyQuery>;
export type ProductListQueryResult = Apollo.QueryResult<ProductListQuery, ProductListQueryVariables>;
export const PurchaserContactListDocument = gql`
  query purchaserContactList($query: String, $orderBy: String, $skip: Int, $take: Int) {
    purchaserContactList(query: $query, orderBy: $orderBy, skip: $skip, take: $take) {
      totalCount
      contactList {
        id
        name
        type
        place {
          id
        }
      }
    }
  }
`;

/**
 * __usePurchaserContactListQuery__
 *
 * To run a query within a React component, call `usePurchaserContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaserContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaserContactListQuery({
 *   variables: {
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePurchaserContactListQuery(
  baseOptions?: Apollo.QueryHookOptions<PurchaserContactListQuery, PurchaserContactListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PurchaserContactListQuery, PurchaserContactListQueryVariables>(
    PurchaserContactListDocument,
    options,
  );
}
export function usePurchaserContactListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PurchaserContactListQuery, PurchaserContactListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PurchaserContactListQuery, PurchaserContactListQueryVariables>(
    PurchaserContactListDocument,
    options,
  );
}
export type PurchaserContactListQueryHookResult = ReturnType<typeof usePurchaserContactListQuery>;
export type PurchaserContactListLazyQueryHookResult = ReturnType<typeof usePurchaserContactListLazyQuery>;
export type PurchaserContactListQueryResult = Apollo.QueryResult<
  PurchaserContactListQuery,
  PurchaserContactListQueryVariables
>;
export const PurchaserOrderListDocument = gql`
  query purchaserOrderList($query: String, $orderBy: String, $skip: Int, $take: Int) {
    orders {
      purchaser {
        orderList(query: $query, orderBy: $orderBy, skip: $skip, take: $take) {
          totalCount
          items {
            id
            orderId
            createdAt
            createdBy
            orderDate
            type
            totalNet
            storeContact {
              id
              name
              place {
                id
              }
            }
            orderStatus {
              id
              createdBy
              createdAt
              name
            }
            paymentStatus {
              id
              statusId
              createdBy
              createdAt
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __usePurchaserOrderListQuery__
 *
 * To run a query within a React component, call `usePurchaserOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaserOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaserOrderListQuery({
 *   variables: {
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePurchaserOrderListQuery(
  baseOptions?: Apollo.QueryHookOptions<PurchaserOrderListQuery, PurchaserOrderListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PurchaserOrderListQuery, PurchaserOrderListQueryVariables>(
    PurchaserOrderListDocument,
    options,
  );
}
export function usePurchaserOrderListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PurchaserOrderListQuery, PurchaserOrderListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PurchaserOrderListQuery, PurchaserOrderListQueryVariables>(
    PurchaserOrderListDocument,
    options,
  );
}
export type PurchaserOrderListQueryHookResult = ReturnType<typeof usePurchaserOrderListQuery>;
export type PurchaserOrderListLazyQueryHookResult = ReturnType<typeof usePurchaserOrderListLazyQuery>;
export type PurchaserOrderListQueryResult = Apollo.QueryResult<
  PurchaserOrderListQuery,
  PurchaserOrderListQueryVariables
>;
export const PurchaserOrderListByStorePurchaserIdDocument = gql`
  query purchaserOrderListByStorePurchaserId(
    $storePurchaserId: ID!
    $query: String
    $orderBy: String
    $skip: Int
    $take: Int
  ) {
    orders {
      purchaser {
        orderListByStorePurchaserId(
          storePurchaserId: $storePurchaserId
          query: $query
          orderBy: $orderBy
          skip: $skip
          take: $take
        ) {
          totalCount
          items {
            id
            orderId
            createdAt
            createdBy
            orderDate
            type
            totalNet
            storeContact {
              id
              name
              place {
                id
              }
            }
            orderStatus {
              id
              createdBy
              createdAt
              name
            }
            paymentStatus {
              id
              statusId
              createdBy
              createdAt
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __usePurchaserOrderListByStorePurchaserIdQuery__
 *
 * To run a query within a React component, call `usePurchaserOrderListByStorePurchaserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaserOrderListByStorePurchaserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaserOrderListByStorePurchaserIdQuery({
 *   variables: {
 *      storePurchaserId: // value for 'storePurchaserId'
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePurchaserOrderListByStorePurchaserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    PurchaserOrderListByStorePurchaserIdQuery,
    PurchaserOrderListByStorePurchaserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PurchaserOrderListByStorePurchaserIdQuery, PurchaserOrderListByStorePurchaserIdQueryVariables>(
    PurchaserOrderListByStorePurchaserIdDocument,
    options,
  );
}
export function usePurchaserOrderListByStorePurchaserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PurchaserOrderListByStorePurchaserIdQuery,
    PurchaserOrderListByStorePurchaserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PurchaserOrderListByStorePurchaserIdQuery,
    PurchaserOrderListByStorePurchaserIdQueryVariables
  >(PurchaserOrderListByStorePurchaserIdDocument, options);
}
export type PurchaserOrderListByStorePurchaserIdQueryHookResult = ReturnType<
  typeof usePurchaserOrderListByStorePurchaserIdQuery
>;
export type PurchaserOrderListByStorePurchaserIdLazyQueryHookResult = ReturnType<
  typeof usePurchaserOrderListByStorePurchaserIdLazyQuery
>;
export type PurchaserOrderListByStorePurchaserIdQueryResult = Apollo.QueryResult<
  PurchaserOrderListByStorePurchaserIdQuery,
  PurchaserOrderListByStorePurchaserIdQueryVariables
>;
export const StockChangeDocument = gql`
  query stockChange($id: ID!) {
    stockChange(id: $id) {
      id
      type
      createdAt
      inStockItemList {
        id
        type
        product {
          id
          name
        }
      }
      outStockItemList {
        id
        type
        product {
          id
          name
        }
      }
      inProductIdList
      inProductList {
        id
        productId
        name
      }
      outProductIdList
      outProductList {
        id
        productId
        name
      }
    }
  }
`;

/**
 * __useStockChangeQuery__
 *
 * To run a query within a React component, call `useStockChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockChangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStockChangeQuery(baseOptions: Apollo.QueryHookOptions<StockChangeQuery, StockChangeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StockChangeQuery, StockChangeQueryVariables>(StockChangeDocument, options);
}
export function useStockChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StockChangeQuery, StockChangeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StockChangeQuery, StockChangeQueryVariables>(StockChangeDocument, options);
}
export type StockChangeQueryHookResult = ReturnType<typeof useStockChangeQuery>;
export type StockChangeLazyQueryHookResult = ReturnType<typeof useStockChangeLazyQuery>;
export type StockChangeQueryResult = Apollo.QueryResult<StockChangeQuery, StockChangeQueryVariables>;
export const CreateStockChangeDocument = gql`
  mutation createStockChange($stockChange: StockChangeInput!) {
    createStockChange(stockChange: $stockChange)
  }
`;

/**
 * __useCreateStockChangeMutation__
 *
 * To run a mutation, you first call `useCreateStockChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockChangeMutation, { data, loading, error }] = useCreateStockChangeMutation({
 *   variables: {
 *      stockChange: // value for 'stockChange'
 *   },
 * });
 */
export function useCreateStockChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStockChangeMutation, CreateStockChangeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStockChangeMutation, CreateStockChangeMutationVariables>(
    CreateStockChangeDocument,
    options,
  );
}
export type CreateStockChangeMutationHookResult = ReturnType<typeof useCreateStockChangeMutation>;
export type CreateStockChangeMutationResult = Apollo.MutationResult<CreateStockChangeMutation>;
export type CreateStockChangeMutationOptions = Apollo.BaseMutationOptions<
  CreateStockChangeMutation,
  CreateStockChangeMutationVariables
>;
export const StockChangeListDocument = gql`
  query stockChangeList($orderBy: String!, $skip: Int!, $take: Int!) {
    stockChangeList(orderBy: $orderBy, skip: $skip, take: $take) {
      totalCount
      items {
        id
        type
        createdAt
        inProductIdList
        inProductList {
          id
          productId
          name
        }
        outProductIdList
        outProductList {
          id
          productId
          name
        }
      }
    }
  }
`;

/**
 * __useStockChangeListQuery__
 *
 * To run a query within a React component, call `useStockChangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockChangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockChangeListQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStockChangeListQuery(
  baseOptions: Apollo.QueryHookOptions<StockChangeListQuery, StockChangeListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StockChangeListQuery, StockChangeListQueryVariables>(StockChangeListDocument, options);
}
export function useStockChangeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StockChangeListQuery, StockChangeListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StockChangeListQuery, StockChangeListQueryVariables>(StockChangeListDocument, options);
}
export type StockChangeListQueryHookResult = ReturnType<typeof useStockChangeListQuery>;
export type StockChangeListLazyQueryHookResult = ReturnType<typeof useStockChangeListLazyQuery>;
export type StockChangeListQueryResult = Apollo.QueryResult<StockChangeListQuery, StockChangeListQueryVariables>;
export const StockItemDocument = gql`
  query stockItem($id: ID!) {
    stockItem(id: $id) {
      id
      type
      createdAt
      inPriceNet
      inDateTime
      outPriceNet
      outDateTime
      product {
        id
        name
      }
      inOrder {
        id
        orderId
      }
      outOrder {
        id
        orderId
      }
      inStockChange {
        id
        type
        inStockItemList {
          id
        }
      }
      outStockChange {
        id
        type
        outStockItemList {
          id
        }
      }
    }
  }
`;

/**
 * __useStockItemQuery__
 *
 * To run a query within a React component, call `useStockItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStockItemQuery(baseOptions: Apollo.QueryHookOptions<StockItemQuery, StockItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StockItemQuery, StockItemQueryVariables>(StockItemDocument, options);
}
export function useStockItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StockItemQuery, StockItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StockItemQuery, StockItemQueryVariables>(StockItemDocument, options);
}
export type StockItemQueryHookResult = ReturnType<typeof useStockItemQuery>;
export type StockItemLazyQueryHookResult = ReturnType<typeof useStockItemLazyQuery>;
export type StockItemQueryResult = Apollo.QueryResult<StockItemQuery, StockItemQueryVariables>;
export const StockItemListDocument = gql`
  query stockItemList($orderBy: String!, $skip: Int!, $take: Int!) {
    stockItemList(orderBy: $orderBy, skip: $skip, take: $take) {
      totalCount
      items {
        id
        type
        createdAt
        inPriceNet
        inDateTime
        outPriceNet
        outDateTime
        product {
          id
          name
        }
        inOrder {
          id
          orderId
        }
        outOrder {
          id
          orderId
        }
        inStockChange {
          id
          type
        }
        outStockChange {
          id
          type
        }
      }
    }
  }
`;

/**
 * __useStockItemListQuery__
 *
 * To run a query within a React component, call `useStockItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockItemListQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStockItemListQuery(
  baseOptions: Apollo.QueryHookOptions<StockItemListQuery, StockItemListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StockItemListQuery, StockItemListQueryVariables>(StockItemListDocument, options);
}
export function useStockItemListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StockItemListQuery, StockItemListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StockItemListQuery, StockItemListQueryVariables>(StockItemListDocument, options);
}
export type StockItemListQueryHookResult = ReturnType<typeof useStockItemListQuery>;
export type StockItemListLazyQueryHookResult = ReturnType<typeof useStockItemListLazyQuery>;
export type StockItemListQueryResult = Apollo.QueryResult<StockItemListQuery, StockItemListQueryVariables>;
export const StoreContactDocument = gql`
  query storeContact($id: String!) {
    storeContact(id: $id) {
      id
      name
      place {
        id
      }
    }
  }
`;

/**
 * __useStoreContactQuery__
 *
 * To run a query within a React component, call `useStoreContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreContactQuery(
  baseOptions: Apollo.QueryHookOptions<StoreContactQuery, StoreContactQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreContactQuery, StoreContactQueryVariables>(StoreContactDocument, options);
}
export function useStoreContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoreContactQuery, StoreContactQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreContactQuery, StoreContactQueryVariables>(StoreContactDocument, options);
}
export type StoreContactQueryHookResult = ReturnType<typeof useStoreContactQuery>;
export type StoreContactLazyQueryHookResult = ReturnType<typeof useStoreContactLazyQuery>;
export type StoreContactQueryResult = Apollo.QueryResult<StoreContactQuery, StoreContactQueryVariables>;
export const StoreContactListDocument = gql`
  query storeContactList($query: String, $orderBy: String, $skip: Int, $take: Int) {
    storeContactList(query: $query, orderBy: $orderBy, skip: $skip, take: $take) {
      totalCount
      contactList {
        id
        name
        place {
          id
        }
      }
    }
  }
`;

/**
 * __useStoreContactListQuery__
 *
 * To run a query within a React component, call `useStoreContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreContactListQuery({
 *   variables: {
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStoreContactListQuery(
  baseOptions?: Apollo.QueryHookOptions<StoreContactListQuery, StoreContactListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreContactListQuery, StoreContactListQueryVariables>(StoreContactListDocument, options);
}
export function useStoreContactListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoreContactListQuery, StoreContactListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreContactListQuery, StoreContactListQueryVariables>(StoreContactListDocument, options);
}
export type StoreContactListQueryHookResult = ReturnType<typeof useStoreContactListQuery>;
export type StoreContactListLazyQueryHookResult = ReturnType<typeof useStoreContactListLazyQuery>;
export type StoreContactListQueryResult = Apollo.QueryResult<StoreContactListQuery, StoreContactListQueryVariables>;
export const StoreOrderListDocument = gql`
  query storeOrderList($query: String, $orderBy: String, $skip: Int, $take: Int) {
    orders {
      store {
        orderList(query: $query, orderBy: $orderBy, skip: $skip, take: $take) {
          totalCount
          items {
            id
            orderId
            createdAt
            createdBy
            orderDate
            type
            totalNet
            purchaserContact {
              id
              name
              place {
                id
              }
            }
            orderStatus {
              id
              createdBy
              createdAt
              name
            }
            paymentStatus {
              id
              statusId
              createdBy
              createdAt
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useStoreOrderListQuery__
 *
 * To run a query within a React component, call `useStoreOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreOrderListQuery({
 *   variables: {
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStoreOrderListQuery(
  baseOptions?: Apollo.QueryHookOptions<StoreOrderListQuery, StoreOrderListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreOrderListQuery, StoreOrderListQueryVariables>(StoreOrderListDocument, options);
}
export function useStoreOrderListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoreOrderListQuery, StoreOrderListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreOrderListQuery, StoreOrderListQueryVariables>(StoreOrderListDocument, options);
}
export type StoreOrderListQueryHookResult = ReturnType<typeof useStoreOrderListQuery>;
export type StoreOrderListLazyQueryHookResult = ReturnType<typeof useStoreOrderListLazyQuery>;
export type StoreOrderListQueryResult = Apollo.QueryResult<StoreOrderListQuery, StoreOrderListQueryVariables>;
export const StoreOrderListByStorePurchaserIdDocument = gql`
  query storeOrderListByStorePurchaserId(
    $storePurchaserId: ID!
    $query: String
    $orderBy: String
    $skip: Int
    $take: Int
  ) {
    orders {
      store {
        orderListByStorePurchaserId(
          storePurchaserId: $storePurchaserId
          query: $query
          orderBy: $orderBy
          skip: $skip
          take: $take
        ) {
          totalCount
          items {
            id
            orderId
            createdAt
            createdBy
            orderDate
            type
            totalNet
            purchaserContact {
              id
              name
              place {
                id
              }
            }
            orderStatus {
              id
              createdBy
              createdAt
              name
            }
            paymentStatus {
              id
              statusId
              createdBy
              createdAt
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useStoreOrderListByStorePurchaserIdQuery__
 *
 * To run a query within a React component, call `useStoreOrderListByStorePurchaserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreOrderListByStorePurchaserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreOrderListByStorePurchaserIdQuery({
 *   variables: {
 *      storePurchaserId: // value for 'storePurchaserId'
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStoreOrderListByStorePurchaserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreOrderListByStorePurchaserIdQuery,
    StoreOrderListByStorePurchaserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreOrderListByStorePurchaserIdQuery, StoreOrderListByStorePurchaserIdQueryVariables>(
    StoreOrderListByStorePurchaserIdDocument,
    options,
  );
}
export function useStoreOrderListByStorePurchaserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreOrderListByStorePurchaserIdQuery,
    StoreOrderListByStorePurchaserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreOrderListByStorePurchaserIdQuery, StoreOrderListByStorePurchaserIdQueryVariables>(
    StoreOrderListByStorePurchaserIdDocument,
    options,
  );
}
export type StoreOrderListByStorePurchaserIdQueryHookResult = ReturnType<
  typeof useStoreOrderListByStorePurchaserIdQuery
>;
export type StoreOrderListByStorePurchaserIdLazyQueryHookResult = ReturnType<
  typeof useStoreOrderListByStorePurchaserIdLazyQuery
>;
export type StoreOrderListByStorePurchaserIdQueryResult = Apollo.QueryResult<
  StoreOrderListByStorePurchaserIdQuery,
  StoreOrderListByStorePurchaserIdQueryVariables
>;
export const UpdateOrderOrderDateDocument = gql`
  mutation updateOrderOrderDate($orderId: String!, $orderDate: DateTime!, $type: String!) {
    updateOrderOrderDate(orderId: $orderId, orderDate: $orderDate, type: $type) {
      id
      orderDate
      type
    }
  }
`;

/**
 * __useUpdateOrderOrderDateMutation__
 *
 * To run a mutation, you first call `useUpdateOrderOrderDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderOrderDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderOrderDateMutation, { data, loading, error }] = useUpdateOrderOrderDateMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      orderDate: // value for 'orderDate'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateOrderOrderDateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrderOrderDateMutation, UpdateOrderOrderDateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderOrderDateMutation, UpdateOrderOrderDateMutationVariables>(
    UpdateOrderOrderDateDocument,
    options,
  );
}
export type UpdateOrderOrderDateMutationHookResult = ReturnType<typeof useUpdateOrderOrderDateMutation>;
export type UpdateOrderOrderDateMutationResult = Apollo.MutationResult<UpdateOrderOrderDateMutation>;
export type UpdateOrderOrderDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderOrderDateMutation,
  UpdateOrderOrderDateMutationVariables
>;
export const UpdateOrderStatusDocument = gql`
  mutation updateOrderStatus($orderId: String!, $statusTypeId: String!) {
    updateOrderStatus(orderId: $orderId, statusTypeId: $statusTypeId) {
      id
      orderStatus {
        type {
          id
        }
      }
    }
  }
`;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      statusTypeId: // value for 'statusTypeId'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(
    UpdateOrderStatusDocument,
    options,
  );
}
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
>;
export const UpdateProductGeneralDocument = gql`
  mutation updateProductGeneral($data: UpdateProductGeneralInput!) {
    updateProductGeneral(data: $data) {
      id
      productId
      productUrl
      category {
        id
        name
      }
      name
      description
      volume
      weight
      isOnline
      stockAvailable
      limitedPlaceTypeIdList
      discountTagIdList
      pictureList {
        order
        fileName
        extension
        originalFileName
      }
    }
  }
`;

/**
 * __useUpdateProductGeneralMutation__
 *
 * To run a mutation, you first call `useUpdateProductGeneralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductGeneralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductGeneralMutation, { data, loading, error }] = useUpdateProductGeneralMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProductGeneralMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProductGeneralMutation, UpdateProductGeneralMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductGeneralMutation, UpdateProductGeneralMutationVariables>(
    UpdateProductGeneralDocument,
    options,
  );
}
export type UpdateProductGeneralMutationHookResult = ReturnType<typeof useUpdateProductGeneralMutation>;
export type UpdateProductGeneralMutationResult = Apollo.MutationResult<UpdateProductGeneralMutation>;
export type UpdateProductGeneralMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductGeneralMutation,
  UpdateProductGeneralMutationVariables
>;
