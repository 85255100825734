import { Badge, Breadcrumb, Col, PageHeader, Row } from 'antd';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ByAtToolTip from '../components/byAtToolTip';
import { usePurchaserOrderListByStorePurchaserIdQuery, useStoreContactQuery } from '../Generated/graphql';
import { formatCurrency } from '../utils/currency';
import { dateToString } from '../utils/date';

const StoreContactItemScreen: React.FC = () => {
  const { contactId } = useParams<{ contactId: string }>();
  const history = useHistory();

  const storeContactData = useStoreContactQuery({
    variables: {
      id: contactId,
    },
  });

  // const product = useProductQuery({ variables: { id: productId } });
  // const { categoryList, refreshCategoryList } = useContext(CommonDataContext);

  // const [form] = Form.useForm();
  // const [dis, setDis] = useState(true);

  // const [updateProductGeneralMutation, { data, loading, error }] = useUpdateProductGeneralMutation();

  // const onFormLayoutChange = (changedValues: unknown, values: unknown) => {
  //   console.log('cv', changedValues);
  //   setDis(!form.isFieldsTouched());
  // };

  const data = usePurchaserOrderListByStorePurchaserIdQuery({
    variables: {
      storePurchaserId: contactId,
      skip: 0,
      take: 20,
    },
  });

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/purchaser/stores">Stores</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Store</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/purchaser/stores')}
        title={storeContactData.loading ? '...loading' : storeContactData.data?.storeContact?.name}
        // title={product.loading ? '...loading' : product.data?.product?.name}
        // subTitle="This is a subtitle"
        // extra={[
        //   <Button key="1" type="primary">
        //     Save
        //   </Button>,
        //   <DropdownMenu key="more" />,
        // ]}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        {data.loading
          ? 'loading'
          : data.data?.orders.purchaser.orderListByStorePurchaserId?.items.map((x) => (
              <Row key={x.id} gutter={8}>
                <Col xs={2}>
                  <Link to={`/purchaser/orders/${x.id}`}>{x.orderId}</Link>
                </Col>
                <Col xs={3}></Col>
                <Col xs={3} style={{ textAlign: 'right' }}>
                  {formatCurrency(x.totalNet)}
                </Col>
                <Col xs={3}>{x.type === 'invoice' ? 'Purchase' : 'Consignment'}</Col>
                <Col xs={4}>
                  <ByAtToolTip by={x.orderStatus.createdBy} at={x.orderStatus.createdAt}>
                    {x.orderStatus.name}
                  </ByAtToolTip>
                </Col>
                <Col xs={4}>
                  <ByAtToolTip by={x.paymentStatus.createdBy} at={x.paymentStatus.createdAt}>
                    <Badge
                      status={
                        x.paymentStatus.statusId === 'unpaid'
                          ? 'error'
                          : x.paymentStatus.statusId === 'unconfirmed'
                          ? 'warning'
                          : x.paymentStatus.statusId === 'paid'
                          ? 'success'
                          : 'default'
                      }
                      text={x.paymentStatus.name}
                    ></Badge>
                  </ByAtToolTip>
                </Col>

                <Col xs={4}>{dateToString(x.orderDate)}</Col>
              </Row>
            ))}
      </div>
    </div>
  );
};

export default StoreContactItemScreen;
