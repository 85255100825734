import { Breadcrumb, Button, Col, Form, Input, PageHeader, Row, Select, Switch, Tabs } from 'antd';
import React, { Fragment, useContext, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useProductQuery, useUpdateProductGeneralMutation } from '../Generated/graphql';
import { SyncOutlined } from '@ant-design/icons';
import { CommonDataContext } from '../contexts/commonDataContext';
import TextArea from 'antd/lib/input/TextArea';
import ProductItemPictures from './ProductItemPictures';
import ProductItemDiscount from './ProductItemDiscount';
import { dateTimeString } from '../utils/date';
import { formatCurrency } from '../utils/currency';

const ProductItemScreen: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const history = useHistory();

  const product = useProductQuery({ variables: { id: productId } });
  const { categoryList, refreshCategoryList } = useContext(CommonDataContext);

  const [form] = Form.useForm();
  const [dis, setDis] = useState(true);

  const [updateProductGeneralMutation, { loading }] = useUpdateProductGeneralMutation();

  // const DropdownMenu = () => {
  //   return (
  //     <Dropdown key="more" overlay={menu}>
  //       <Button
  //         style={{
  //           border: 'none',
  //           padding: 0,
  //         }}
  //       >
  //         <EllipsisOutlined
  //           style={{
  //             fontSize: 20,
  //             verticalAlign: 'top',
  //           }}
  //         />
  //       </Button>
  //     </Dropdown>
  //   );
  // };

  const onFormLayoutChange = (changedValues: unknown) => {
    console.log('cv', changedValues);
    setDis(!form.isFieldsTouched());
  };

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/store/products">Products</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Product</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/store/products')}
        title={product.loading ? '...loading' : product.data?.product?.name}
        // subTitle="This is a subtitle"
        // extra={[
        //   <Button key="1" type="primary">
        //     Save
        //   </Button>,
        //   <DropdownMenu key="more" />,
        // ]}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div className="site-layout-background" style={{ marginTop: 5, paddingLeft: 25 }}>
        {product.loading ? (
          <div>...loading</div>
        ) : (
          <Tabs defaultActiveKey="1" tabBarGutter={30}>
            <Tabs.TabPane tab="General" key="1">
              <div>
                <Form
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 14 }}
                  layout="horizontal"
                  form={form}
                  initialValues={{
                    productId: product.data?.product?.productId,
                    name: product.data?.product?.name,
                    categoryId: product.data?.product?.category?.id,
                    description: product.data?.product?.description,
                    volume: product.data?.product?.volume,
                    weight: product.data?.product?.weight,
                    isOnline: product.data?.product?.isOnline,
                    stockAvailable: product.data?.product?.stockAvailable,
                    limitedPlaceTypeIdList: product.data?.product?.limitedPlaceTypeIdList,
                  }}
                  onValuesChange={onFormLayoutChange}
                  onFinish={async () => {
                    let volume = parseFloat(form.getFieldsValue().volume);
                    if (isNaN(volume)) volume = 0;
                    let weight = parseFloat(form.getFieldsValue().weight);
                    if (isNaN(weight)) weight = 0;

                    const r = await updateProductGeneralMutation({
                      variables: {
                        data: {
                          id: product.data?.product?.id ?? '',
                          ...form.getFieldsValue(),
                          ...{ volume, weight },
                        },
                      },
                    });

                    form.setFieldsValue(r.data?.updateProductGeneral);
                  }}
                >
                  <Form.Item label="Product Id" name="productId">
                    <Input placeholder="input placeholder" />
                  </Form.Item>
                  <Form.Item label="Name" name="name">
                    <Input placeholder="Name" />
                  </Form.Item>
                  <Form.Item label="Category">
                    <Form.Item
                      name="categoryId"
                      rules={[{ required: true, message: 'Please select your category!' }]}
                      style={{ display: 'inline-block', width: 'calc(100% - 37px)', marginRight: 5, marginBottom: 0 }}
                    >
                      <Select
                        placeholder="Please select a category"
                        showSearch
                        filterOption={(input, option) =>
                          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {categoryList.map((x) => (
                          <Select.Option key={x.id} value={x.id}>
                            {x.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item style={{ display: 'inline-block', marginBottom: 0 }}>
                      <Button type="default" icon={<SyncOutlined />} onClick={() => refreshCategoryList()}></Button>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item label="Description" name="description">
                    <TextArea rows={4} />
                  </Form.Item>
                  <Form.Item label="Volume" name="volume" tooltip="Volume is used for reporting.">
                    <Input placeholder="Volume" type="number" />
                  </Form.Item>
                  <Form.Item label="Weight" name="weight" tooltip="Weight is used for shipping.">
                    <Input placeholder="Weight" type="number" />
                  </Form.Item>
                  <Form.Item label="Allowed place types" name="limitedPlaceTypeIdList" tooltip="Test2">
                    <Select mode="multiple" allowClear placeholder="Please select">
                      <Select.Option value="private">Private</Select.Option>
                      <Select.Option value="business">Business</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Is online" name="isOnline" valuePropName="checked">
                    <Switch></Switch>
                  </Form.Item>
                  <Form.Item label="Stock available" name="stockAvailable" valuePropName="checked">
                    <Switch></Switch>
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 6, offset: 14 }} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" disabled={dis} loading={loading}>
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Pricing" key="2">
              Content of Tab Pane 2
            </Tabs.TabPane>
            <Tabs.TabPane tab="Images" key="3">
              {product.data?.product && (
                <ProductItemPictures
                  id={product.data?.product.id}
                  pictureList={product.data?.product?.pictureList}
                ></ProductItemPictures>
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Discounts" key="4">
              {product.data?.product?.discountList.map((d) => {
                return (
                  product.data?.product?.id && (
                    <ProductItemDiscount
                      key={d.id}
                      productId={product.data?.product.id}
                      discount={d}
                    ></ProductItemDiscount>
                  )
                );
              })}
              <div style={{ marginTop: 10 }}>
                <div style={{ marginBottom: 10 }}>New</div>
                {product.data?.product?.id && (
                  <ProductItemDiscount productId={product.data?.product.id} discount={undefined}></ProductItemDiscount>
                )}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Stock" key="5">
              {product.data?.product?.stockItemList.items.map((x) => (
                <Row key={x.id} style={{ padding: 10, borderBottom: '1px solid lightgray' }}>
                  <Col xs={3}>
                    <div>{dateTimeString(x.inDateTime, '-')}</div>
                    <div>
                      {x.inOrder && <Link to={'/purchaser/orders/' + x.inOrder.id}>{x.inOrder.orderId}</Link>}
                      {x.inStockChange && (
                        <Link to={'/store/stock/changes/' + x.inStockChange.id}>Change ({x.inStockChange.type})</Link>
                      )}
                    </div>
                    <div>{formatCurrency(x.inPriceNet, '-')}</div>
                  </Col>
                  <Col>
                    <div>{dateTimeString(x.outDateTime, '-')}</div>
                    {x.outOrder && <Link to={'/store/orders/' + x.outOrder.id}>{x.outOrder.orderId}</Link>}
                    {x.outStockChange && (
                      <Link to={'/store/stock/changes/' + x.outStockChange.id}>Change ({x.inStockChange?.type})</Link>
                    )}
                    <div>{formatCurrency(x.outPriceNet, '-')}</div>
                  </Col>
                </Row>
              ))}
            </Tabs.TabPane>
          </Tabs>
        )}
      </div>
    </div>
  );

  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current < moment().endOf('day');
  // }

  // function range(start, end) {
  //   const result: any[] = [];
  //   for (let i = start; i < end; i++) {
  //     result.push(i);
  //   }
  //   return result;
  // }

  // function disabledRangeTime(_, type) {
  //   if (type === 'start') {
  //     return {
  //       disabledHours: () => range(0, 60).splice(4, 20),
  //       disabledMinutes: () => range(30, 60),
  //       disabledSeconds: () => [55, 56],
  //     };
  //   }
  //   return {
  //     disabledHours: () => range(0, 60).splice(20, 4),
  //     disabledMinutes: () => range(0, 31),
  //     disabledSeconds: () => [55, 56],
  //   };
  // }
};

export default ProductItemScreen;
